import React from 'react';

const StatCard = ({ 
  title, 
  value, 
  icon, 
  change, 
  changeType = 'increase', 
  description,
  color = 'blue'
}) => {
  // Determine change classes based on type
  const changeClasses = changeType === 'increase' 
    ? 'admin-stat-positive' 
    : changeType === 'decrease' 
      ? 'admin-stat-negative' 
      : 'admin-stat-neutral';
  
  // Determine change icon
  const changeIcon = changeType === 'increase' 
    ? '↑' 
    : changeType === 'decrease' 
      ? '↓' 
      : '';
  
  // Determine background color class
  const colorClass = `admin-bg-${color}`;
  
  return (
    <div className="admin-stat-card">
      <div className="admin-stat-header">
        <div>
          <p className="admin-stat-title">{title}</p>
          <p className="admin-stat-value">{value}</p>
          
          {(change || description) && (
            <div className="admin-stat-change">
              {change && (
                <span className={changeClasses}>
                  {changeIcon} {change}%
                </span>
              )}
              {description && (
                <span className="admin-stat-neutral ml-2">{description}</span>
              )}
            </div>
          )}
        </div>
        
        {icon && (
          <div className={`admin-stat-icon ${colorClass}`}>
            {icon}
          </div>
        )}
      </div>
    </div>
  );
};

export default StatCard;