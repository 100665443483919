import React, { useState } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TablePagination,
  TableSortLabel,
  Paper,
  TextField,
  InputAdornment,
  Box
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function MaterialTable({
  columns,
  data,
  isLoading = false,
  showSearch = true
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');

  // Handle sorting
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Handle search filter
  const filteredData = data.filter(row => {
    if (!searchQuery) return true;
    
    // Search across all string and number values in the row
    return Object.values(row).some(value => {
      // Skip if value is null, undefined, or an object
      if (value === null || value === undefined || typeof value === 'object') return false;
      
      // Convert value to string and search
      return String(value).toLowerCase().includes(searchQuery.toLowerCase());
    });
  });

  // Sort the data based on current sorting criteria
  const sortedData = [...filteredData].sort((a, b) => {
    if (!orderBy) return 0;
    
    const valueA = a[orderBy];
    const valueB = b[orderBy];
    
    // Handle nulls, undefined, etc.
    if (valueA === valueB) return 0;
    if (valueA === null || valueA === undefined) return order === 'asc' ? -1 : 1;
    if (valueB === null || valueB === undefined) return order === 'asc' ? 1 : -1;
    
    // Numeric comparison
    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return order === 'asc' ? valueA - valueB : valueB - valueA;
    }
    
    // String comparison
    return order === 'asc' 
      ? String(valueA).localeCompare(String(valueB))
      : String(valueB).localeCompare(String(valueA));
  });

  // Get current page of data
  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Custom render function for cell content
  const renderCellContent = (column, row) => {
    if (column.render) {
      return column.render(row);
    }
    if (column.id === 'change' && row.change) {
      const changeType = row.changeType || (parseFloat(row.change) > 0 ? 'increase' : 'decrease');
      return (
        <span className={`inline-flex items-center ${
          changeType === 'increase' ? 'text-green-600' : 
          changeType === 'decrease' ? 'text-red-600' : 'text-gray-600'
        }`}>
          {changeType === 'increase' ? '↑' : changeType === 'decrease' ? '↓' : ''} {row.change}
        </span>
      );
    }
    return row[column.id];
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {/* Search bar */}
      {showSearch && (
        <Box sx={{ p: 2 }}>
          <TextField
            fullWidth
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
          />
        </Box>
      )}
      
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align || 'left'}
                  sortDirection={orderBy === column.id ? order : false}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.sortable !== false ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => handleRequestSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            ) : paginatedData.length > 0 ? (
              paginatedData.map((row, rowIndex) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                  {columns.map((column) => (
                    <TableCell 
                      key={column.id} 
                      align={column.align || 'left'}
                    >
                      {renderCellContent(column, row)}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default MaterialTable;