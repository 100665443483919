import axios from 'axios';
import { API_BASE_URL } from '../config';
import { getAuthHeader } from './auth';

// Get all users
export const getUsers = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/admin/users`, 
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Failed to fetch users';
  }
};

// Update user
export const updateUser = async (userId, userData) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/admin/users/${userId}`, 
      userData, 
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Failed to update user';
  }
};

// Update user password
export const updateUserPassword = async (userId, newPassword) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/admin/users/${userId}/password`, 
      { password: newPassword }, 
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Failed to update password';
  }
};

// Get all properties (including inactive)
export const getProperties = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/admin/properties`, 
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Failed to fetch properties';
  }
};

// Update property
export const updateProperty = async (propertyId, propertyData) => {
  try {
    // Check if propertyData is already FormData
    const data = propertyData instanceof FormData 
      ? propertyData 
      : Object.entries(propertyData).reduce((formData, [key, value]) => {
          formData.append(key, value);
          return formData;
        }, new FormData());
    
    const response = await axios.put(
      `${API_BASE_URL}/admin/properties/${propertyId}`, 
      data, 
      { 
        headers: {
          ...getAuthHeader(),
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in updateProperty:', error);
    throw error.response?.data?.error || 'Failed to update property';
  }
};

// Delete property
export const deleteProperty = async (propertyId) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/admin/properties/${propertyId}`, 
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Failed to delete property';
  }
};

// Get price per m² data
export const getPricePerM2Data = async (timeRange, filters = {}) => {
  try {
    // Prepare query parameters
    const params = new URLSearchParams({
      timeRange: timeRange || 'month',
      ...filters
    });
    
    const response = await axios.get(
      `${API_BASE_URL}/admin/analytics/price-per-m2?${params.toString()}`, 
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching price per m² data:', error);
    throw error.response?.data?.error || 'Failed to fetch price per m² data';
  }
};

// Create admin user
export const createAdmin = async (email, adminSecret) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/create-admin`, 
      { email, adminSecret }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Failed to create admin';
  }
};