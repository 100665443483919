import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ListingCard from '../components/ListingCard';
import ListingModal from '../components/ListingModal';
import MapView from '../components/MapView';
import Pagination from '../components/Pagination';
import { getListings, getListing } from '../services/api';
import { getCoordinates, getCityCountryOptions, DEFAULT_CARD_COUNT } from '../utils/helpers';
import { isAuthenticated, getUser } from '../services/auth';
import Select from 'react-select'; // Make sure to install react-select



const Search = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [listings, setListings] = useState([]);
  const [selectedListing, setSelectedListing] = useState(null);
  const [hoveredListing, setHoveredListing] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showDesktopMap, setShowDesktopMap] = useState(true); // Default to true for desktop
  const [isLoading, setIsLoading] = useState(false);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [selectedNeighborhoods, setSelectedNeighborhoods] = useState([]);
  const [showNeighborhoodFilter, setShowNeighborhoodFilter] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({
    cityCountry: '',
    postType: 'Sale',
    price: '',
    bedrooms: '',
    propertyType: ''
  });
  const [userFilter, setUserFilter] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 9.1450, lng: 18.4275 });
  const listingsColumnRef = useRef(null);
  const isUserSignedIn = isAuthenticated();
  const user = getUser();
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [selectedListingId, setSelectedListingId] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0); // Add this new state
  // Inside Search component, add these state variables
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalListings, setTotalListings] = useState(0);
  const [itemsPerPage] = useState(DEFAULT_CARD_COUNT); // Reduce from 100 to 20 items per page

  // Add to tempFilters
  const [tempFilters, setTempFilters] = useState({
    cityCountry: '',
    postType: 'Sale',
    price: '',
    bedrooms: '',
    propertyType: '',
    neighborhoods: [] // Add this
  });

  const [tempUserFilter, setTempUserFilter] = useState(false);

  // Function to fetch neighborhoods when city changes
  const fetchNeighborhoods = async (country, city) => {
    try {
      const response = await fetch(`/data/neighborhoods/${country.toLowerCase()}/${city.toLowerCase()}.json`);
      if (response.ok) {
        const data = await response.json();
        const neighborhoodOptions = Object.keys(data).map(n => ({
          value: n,
          label: n
        }));
        setNeighborhoods(neighborhoodOptions);
        setShowNeighborhoodFilter(true);
      } else {
        setNeighborhoods([]);
        setShowNeighborhoodFilter(false);
        setSelectedNeighborhoods([]);
      }
    } catch (error) {
      console.error('Error fetching neighborhoods:', error);
      setNeighborhoods([]);
      setShowNeighborhoodFilter(false);
      setSelectedNeighborhoods([]);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const listingId = searchParams.get('listingId');
    
    const navigationState = location.state;
    if (navigationState?.modalOpen || navigationState?.modalClose) {
      return;
    }
    
    if (listingId && !selectedListing) {
      loadListingDetails(listingId);
    }
    
    const newFilters = {
      cityCountry: searchParams.get('cityCountry') || '',
      postType: searchParams.get('postType') || 'Sale',
      price: searchParams.get('price') || '',
      bedrooms: searchParams.get('bedrooms') || '',
      propertyType: searchParams.get('propertyType') || '',
      neighborhoods: searchParams.get('neighborhoods') ? 
        searchParams.get('neighborhoods').split(',') : [] // Add neighborhoods from URL
    };
    const userOnly = searchParams.get('userOnly') === 'true';
    const page = parseInt(searchParams.get('page')) || 1;
    
    setCurrentFilters(newFilters);
    setTempFilters(newFilters);
  
    // Restore selected neighborhoods from URL
    if (newFilters.neighborhoods.length > 0) {
      const neighborhoodOptions = newFilters.neighborhoods.map(n => ({
        value: n,
        label: n
      }));
      setSelectedNeighborhoods(neighborhoodOptions);
    }
  
    setUserFilter(userOnly);
    setTempUserFilter(userOnly);
    setCurrentPage(page);
    updateMapCenter(newFilters.cityCountry);
    fetchListings(newFilters, userOnly);
  
  }, [location.search, location.state]);

  // Separate effect for handling direct URL access
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const listingId = searchParams.get('listingId');
    
    // Only load on initial mount if there's a listingId
    if (listingId && !selectedListing) {
      loadListingDetails(listingId);
    }
  }, []); // Empty dependency array for initial mount only

  // This is for neighborhoods
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const cityCountry = searchParams.get('cityCountry');
    
    if (cityCountry) {
      const [city, country] = cityCountry.split(', ');
      fetchNeighborhoods(country, city);
    }
  }, []); // Run once on mount

  const loadListingDetails = async (id) => {
    try {
      const listing = await getListing(id);
      if (listing) {
        setSelectedListing(listing);
        setSelectedListingId(id);
      }
    } catch (error) {
      console.error('Error loading listing details:', error);
    }
  };


  const handleListingClick = (listing) => {
    setSelectedListing(listing);
    setSelectedListingId(listing.id);
    // Update URL without triggering a refresh
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('listingId', listing.id);
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
      state: { modalOpen: true }, // Add state to prevent refresh
      preventScrollReset: true
    });
  };

  const handleCloseModal = () => {
    setSelectedListing(null);
    setSelectedListingId(null);
    // Remove listingId from URL without triggering a refresh
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('listingId');
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
      state: { modalClose: true }, // Add state to prevent refresh
      preventScrollReset: true
    });
  };

  const updateMapCenter = (cityCountry) => {
    if (cityCountry) {
      const [city, country] = cityCountry.split(', ');
      if (city && country) {
        console.log(`Setting map center for ${city}, ${country}`);
        const coordinates = getCoordinates(city, country);
        console.log('Coordinates:', coordinates);
        setMapCenter(coordinates);
      }
    }
  };

  // Update the fetchListings function
  const fetchListings = async (filters, userOnly = false) => {
    if (filters.cityCountry) {
      setIsLoading(true);
      try {
        const apiFilters = { ...filters };
        if (userOnly && user) {
          apiFilters.userId = user.id;
        }
        
        if (filters.neighborhoods && filters.neighborhoods.length > 0) {
          apiFilters.neighborhoods = filters.neighborhoods;
        }

        // Add pagination parameters
        apiFilters.page = currentPage;
        apiFilters.limit = itemsPerPage; // Use itemsPerPage instead of hardcoded 100
  
        const response = await getListings(apiFilters);
        
        // Update state with response data
        setListings(response.listings || []);
        setTotalPages(Math.max(response.totalPages || 1, 1));
        setTotalListings(response.total || 0);
        
      } catch (error) {
        console.error('Error fetching listings:', error);
        setListings([]);
        setTotalPages(1);
        setTotalListings(0);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Update handlePageChange
  const handlePageChange = (page) => {
    // Scroll to top of listings
    if (listingsColumnRef.current) {
      listingsColumnRef.current.scrollTop = 0;
    }

    // Update URL with new page number while preserving other parameters
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', page);
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });

    setCurrentPage(page);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  
  const toggleMap = () => {
    setShowMap(!showMap);
  };
  
  const toggleDesktopMap = () => {
    setShowDesktopMap(!showDesktopMap);
  };

  // Update handleFilterChange to handle neighborhood selection
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'cityCountry' && value) {
      const [city, country] = value.split(', ');
      fetchNeighborhoods(country, city);
      setSelectedNeighborhoods([]); // Reset neighborhoods when city changes
    }
    
    setTempFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

    // Add neighborhood change handler
    const handleNeighborhoodChange = (selectedOptions) => {
      setSelectedNeighborhoods(selectedOptions);
      setTempFilters(prev => ({
        ...prev,
        neighborhoods: selectedOptions ? selectedOptions.map(opt => opt.value) : []
      }));
    };

  // Update handleUserFilterChange
  const handleUserFilterChange = (e) => {
    setTempUserFilter(e.target.checked);
  };
  
  // Update applyFilters to reset pagination
  const applyFilters = () => {
    setCurrentPage(1);
    
    const filters = { ...tempFilters };
    if (tempUserFilter) {
      filters.userOnly = 'true';
    }
  
    // Update current filters with all temp filters including neighborhoods
    setCurrentFilters(filters);
  
    // Remove empty filters but preserve neighborhoods array
    Object.keys(filters).forEach(key => {
      if (!filters[key] || (Array.isArray(filters[key]) && filters[key].length === 0)) {
        delete filters[key];
      }
    });
  
    // Add neighborhoods to URL if they exist
    const searchParams = new URLSearchParams(filters);
    searchParams.set('page', '1');
    if (filters.neighborhoods && filters.neighborhoods.length > 0) {
      searchParams.set('neighborhoods', filters.neighborhoods.join(','));
    }
  
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });

    fetchListings(filters, tempUserFilter);
  };


  // Update clearFilters
  const clearFilters = () => {
    const clearedFilters = {
      cityCountry: tempFilters.cityCountry, // Preserve current city
      postType: 'Sale',
      price: '',
      bedrooms: '',
      propertyType: '',
      neighborhoods: [] // Clear neighborhoods
    };
    
    setTempFilters(clearedFilters);
    setCurrentFilters(clearedFilters);
    setSelectedNeighborhoods([]); // Clear selected neighborhoods
    setTempUserFilter(false);
    setUserFilter(false);
  
    const searchParams = new URLSearchParams({
      cityCountry: clearedFilters.cityCountry,
      postType: clearedFilters.postType
    });
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  
    fetchListings(clearedFilters, false);
  };

  const handleListingHover = (listing) => {
    setHoveredListing(listing);
  };

  const handleListingLeave = () => {
    setHoveredListing(null);
  };

  const handleEditSuccess = () => {
    // Refresh listings
    fetchListings(currentFilters, userFilter);
  };

  const handleDeleteSuccess = () => {
    setSelectedListing(null);
    // Refresh listings
    fetchListings(currentFilters, userFilter);
  };

  const scrollToListing = (listingId) => {
    const listingElement = document.getElementById(`listing-${listingId}`);
    if (listingElement && listingsColumnRef.current) {
      listingsColumnRef.current.scrollTo({
        top: listingElement.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  const getPriceOptions = () => {
    if (currentFilters.postType === 'Rent') {
      return [
        { value: '0-50000', label: '0 - 50,000' },
        { value: '50000-100000', label: '50,000 - 100,000' },
        { value: '100000-150000', label: '100,000 - 150,000' },
        { value: '150000-200000', label: '150,000 - 200,000' },
        { value: '200000-250000', label: '200,000 - 250,000' },
        { value: '250000-300000', label: '250,000 - 300,000' },
        { value: '300000-350000', label: '300,000 - 350,000' },
        { value: '350000-400000', label: '350,000 - 400,000' },
        { value: '400000-450000', label: '400,000 - 450,000' },
        { value: '450000-500000', label: '450,000 - 500,000' },
        { value: '500000+', label: '500,000+' }
      ];
    } else {
      return [
        { value: '0-5000000', label: '0 - 5M' },
        { value: '5000000-10000000', label: '5M - 10M' },
        { value: '10000000-20000000', label: '10M - 20M' },
        { value: '20000000-30000000', label: '20M - 30M' },
        { value: '30000000-40000000', label: '30M - 40M' },
        { value: '40000000-50000000', label: '40M - 50M' },
        { value: '50000000-100000000', label: '50M - 100M' },
        { value: '100000000-150000000', label: '100M - 150M' },
        { value: '150000000-200000000', label: '150M - 200M' },
        { value: '200000000+', label: '200M+' }
      ];
    }
  };

  const showBedroomsFilter = ['House', 'Apartment'].includes(currentFilters.propertyType);

  const mapPinIcon = (
    <svg className="toggle-btn-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="16" height="16">
      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
      <circle cx="12" cy="10" r="3"></circle>
    </svg>
  );
  
  const filterIcon = (
    <svg className="toggle-btn-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="16" height="16">
      <line x1="4" y1="21" x2="4" y2="14"></line>
      <line x1="4" y1="10" x2="4" y2="3"></line>
      <line x1="12" y1="21" x2="12" y2="12"></line>
      <line x1="12" y1="8" x2="12" y2="3"></line>
      <line x1="20" y1="21" x2="20" y2="16"></line>
      <line x1="20" y1="12" x2="20" y2="3"></line>
      <line x1="1" y1="14" x2="7" y2="14"></line>
      <line x1="9" y1="8" x2="15" y2="8"></line>
      <line x1="17" y1="16" x2="23" y2="16"></line>
    </svg>
  );

  return (
    <div className={`search-page ${!showDesktopMap ? 'map-hidden' : ''}`}>
      {/* Mobile map toggle button */}
      <button className="map-toggle-btn admin-btn admin-bg-green" onClick={toggleMap}>
        {mapPinIcon}
        {showMap ? t('Hide Map') : t('Show Map')}
      </button>
      
      {/* Mobile filters toggle button */}
      <button className="filter-toggle-btn admin-btn admin-btn-primary" onClick={toggleFilters}>
        {filterIcon}
        {showFilters ? t('Hide Filters') : t('Show Filters')}
      </button>

      <div className={`filters-container admin-filters ${showFilters ? 'show' : ''}`}>
        <div className="filters admin-filters-grid">
          <select 
            name="cityCountry" 
            value={tempFilters.cityCountry} 
            onChange={handleFilterChange}
            className="admin-form-select"
          >
            <option value="">{t('Select City, Country')}</option>
            {getCityCountryOptions().map(option => {
              const [city, country] = option.split(', ');
              return (
                <option key={option} value={option}>
                  {t(city)}, {t(country)}
                </option>
              );
            })}
          </select>
          
          {showNeighborhoodFilter && (
            <div className="neighborhood-filter">
              <Select
                isMulti
                name="neighborhoods"
                options={neighborhoods}
                value={selectedNeighborhoods}
                onChange={handleNeighborhoodChange}
                placeholder={t('Select Neighborhoods')}
                classNamePrefix="select"
                className="basic-multi-select"
              />
            </div>
          )}
          <select 
            name="postType" 
            value={tempFilters.postType} 
            onChange={handleFilterChange}
            className="admin-form-select"
          >
            <option value="Sale">{t('For Sale')}</option>
            <option value="Rent">{t('For Rent')}</option>
          </select>
          <select 
            name="propertyType" 
            value={tempFilters.propertyType} 
            onChange={handleFilterChange}
            className="admin-form-select"
          >
            <option value="">{t('Property Type')}</option>
            <option value="House">{t('House')}</option>
            <option value="Apartment">{t('Apartment')}</option>
            <option value="Land">{t('Land')}</option>
            <option value="Warehouse">{t('Warehouse')}</option>
            <option value="Office">{t('Office')}</option>
            <option value="Building">{t('Building')}</option>
          </select>
          {showBedroomsFilter && (
            <select 
              name="bedrooms" 
              value={tempFilters.bedrooms} 
              onChange={handleFilterChange}
              className="admin-form-select"
            >
              <option value="">{t('Bedrooms')}</option>
              <option value="1">1+</option>
              <option value="2">2+</option>
              <option value="3">3+</option>
              <option value="4">4+</option>
            </select>
          )}
          <select 
            name="price" 
            value={tempFilters.price} 
            onChange={handleFilterChange}
            className="admin-form-select"
          >
            <option value="">{t('Price')}</option>
            {getPriceOptions().map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
          {isUserSignedIn && (
            <div className="user-filter">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={tempUserFilter}
                  onChange={handleUserFilterChange}
                />
                <span className="slider"></span>
              </label>
              <span className="filter-label">{t('Show only my listings')}</span>
            </div>
          )}
          
          <div className="filter-buttons-container">
            <button className="apply-filters-btn admin-btn admin-btn-primary" onClick={applyFilters}>
              {t('Apply')}
            </button>
            
            <button className="clear-filters-btn admin-btn admin-btn-danger" onClick={clearFilters}>
              {t('Clear Filters')}
            </button>
            
            {/* Desktop map toggle button */}
            <button className="desktop-map-toggle admin-btn admin-bg-green" onClick={toggleDesktopMap}>
              <span className="toggle-btn-icon">{mapPinIcon}</span>
              <span>{showDesktopMap ? t('Hide Map') : t('Show Map')}</span>
            </button>
          </div>
        </div>
      </div>

      <div 
        className="listings-column" 
        ref={listingsColumnRef}>
        {isLoading ? (
          <div className="admin-chart-loading">{t('Loading...')}</div>
        ) : listings.length > 0 ? (
          <>
            <div className="listings-grid">
              {listings.map(listing => (
                <ListingCard 
                  key={listing.id}
                  id={`listing-${listing.id}`}
                  listing={listing} 
                  onClick={() => handleListingClick(listing)}
                  onMouseEnter={() => handleListingHover(listing)}
                  onMouseLeave={handleListingLeave}
                  isHovered={hoveredListing && hoveredListing.id === listing.id}
                />
              ))}
            </div>
            
            {/* Pagination moved inside the listings column for mobile */}
            <div className="mobile-pagination">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </>
        ) : (
          <div className="admin-card">
            <div className="admin-card-title no-listings-message">
              {t('No listings in this area')}
            </div>
          </div>
        )}
      </div>

      <div className={`map-column ${showMap ? 'show' : ''}`}>
        <MapView 
          listings={listings}
          onMarkerClick={(listing) => {
            setSelectedListing(listing);
            scrollToListing(listing.id);
          }}
          hoveredListing={hoveredListing}
          onMarkerHover={handleListingHover}
          onMarkerLeave={handleListingLeave}
          center={mapCenter}
        />
      </div>

      {/* Desktop pagination displayed outside other columns at the bottom */}
      {!isLoading && listings.length > 0 && (
        <div className="pagination-container desktop-pagination">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      )}

      {selectedListing && (
        <ListingModal 
          listing={selectedListing} 
          onClose={handleCloseModal}
          onDeleteSuccess={handleDeleteSuccess}
        />
      )}

    </div>
  );
};

export default Search;