import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getUser, editProfile } from '../services/auth';
import { getCityCountryOptions } from '../utils/helpers';
import Toast from '../components/Toast';
import MetaTags from '../components/MetaTags';
import { API_BASE_URL } from '../config';

const EditProfilePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    phone: '',
    country: ''
  });
  
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [toast, setToast] = useState({ visible: false, message: '', type: '' });
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setIsLoading(true);
        // First try to get user data from localStorage
        const userData = getUser();
        
        if (userData) {
          console.log('User data from localStorage:', userData);
          
          // If we have basic user data from localStorage, use it
          setFormData({
            fullname: userData.fullname || '',
            email: userData.email || '',
            phone: userData.phone || '',
            country: userData.country || ''
          });
          
          // Then try to fetch detailed profile data from the server
          try {
            const response = await fetch(`${API_BASE_URL}/users/profile`, {
              headers: { 
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
              }
            });
            
            if (response.ok) {
              const detailedUserData = await response.json();
              console.log('Detailed user data from API:', detailedUserData);
              
              if (detailedUserData && detailedUserData.data) {
                setFormData({
                  fullname: detailedUserData.data.fullname || userData.fullname || '',
                  email: detailedUserData.data.email || userData.email || '',
                  phone: detailedUserData.data.phone || userData.phone || '',
                  country: detailedUserData.data.country || userData.country || ''
                });
              }
            } else {
              console.log('API response not OK, status:', response.status);
            }
          } catch (serverError) {
            console.error('Could not fetch detailed profile from server, using local data', serverError);
            // We'll continue with the localStorage data
          }
        } else {
          // No user data in localStorage
          setToast({
            visible: true,
            message: t('notAuthenticated'),
            type: 'error'
          });
          setTimeout(() => navigate('/'), 2000);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setToast({
          visible: true,
          message: t('errorFetchingUserData'),
          type: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchUserData();
  }, [t, navigate]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Special handling for phone (numbers only)
    if (name === 'phone' && value !== '') {
      const numericValue = value.replace(/[^0-9]/g, '');
      if (numericValue !== value) {
        return;
      }
    }
    
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const dataToSubmit = { ...formData };
      
      // Only include password if it was changed
      if (password) {
        dataToSubmit.password = password;
      }
      
      const response = await editProfile(dataToSubmit);
      
      if (response && (response.success || response.data)) {
        // Update localStorage with new user data
        try {
          // Get current user data from localStorage
          const userData = JSON.parse(localStorage.getItem('user')) || {};
          
          // Update with new values (maintaining existing fields)
          const updatedUserData = {
            ...userData,
            fullname: formData.fullname || userData.fullname,
            // Don't update sensitive fields like role or id
          };
          
          // Save back to localStorage
          localStorage.setItem('user', JSON.stringify(updatedUserData));
        } catch (storageError) {
          console.error('Error updating local storage:', storageError);
          // Continue even if localStorage update fails
        }
        
        setToast({
          visible: true,
          message: t('profileUpdatedSuccessfully'),
          type: 'success'
        });
        
        // Navigate back after successful update
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      } else {
        throw new Error(response?.message || t('unknownError'));
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setToast({
        visible: true,
        message: error.message || t('errorUpdatingProfile'),
        type: 'error'
      });
    }
  };
  
  const handleCancel = () => {
    navigate(-1);
  };
  
  if (isLoading) {
    return <div className="page-container loading">{t('loading')}...</div>;
  }
  
  return (
    <div className="page-container edit-profile-page">
      <MetaTags title={t('Edit Profile')} />
      <div className="page-header">
        <h1>{t('Edit Profile')}</h1>
        <button onClick={handleCancel} className="back-button">
          {t('back')}
        </button>
      </div>
      
      <div className="edit-profile-content">
        <form onSubmit={handleSubmit} className="edit-profile-form">
          <div className="form-section">
            <h2>{t('Personal Info')}</h2>
            
            <div className="form-group">
              <label htmlFor="fullname">{t('fullName')} *</label>
              <input
                type="text"
                id="fullname"
                name="fullname"
                value={formData.fullname}
                onChange={handleChange}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="email">{t('email')} *</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="password">{t('password')} {t('leaveEmptyToKeepCurrent')}</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                autoComplete="new-password"
              />
            </div>
          </div>
          
          <div className="form-section">
            <h2>{t('Contact Info')}</h2>
            
            <div className="form-group">
              <label htmlFor="country">{t('country')}</label>
              <select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
              >
                <option value="">{t('select')}</option>
                {getCityCountryOptions().map(option => {
                  const country = option.split(',')[1]?.trim();
                  return country ? (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ) : null;
                }).filter(Boolean)}
              </select>
            </div>
            
            <div className="form-group">
              <label htmlFor="phone">{t('phone')}</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
          </div>
          
          <div className="form-actions">
            <button type="button" onClick={handleCancel} className="cancel-button">
              {t('cancel')}
            </button>
            <button type="submit" className="submit-button">
              {t('Update Profile')}
            </button>
          </div>
        </form>
      </div>
      
      {toast.visible && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ ...toast, visible: false })}
        />
      )}
    </div>
  );
};

export default EditProfilePage;