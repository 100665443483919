import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminLayout from './Layout';
import { 
  PencilIcon, 
  TrashIcon, 
  EyeIcon,
  FunnelIcon,
  XMarkIcon,
  HomeIcon,
  BuildingOfficeIcon,
  CurrencyDollarIcon,
  MapPinIcon,
  ChartBarIcon,
  ChartPieIcon
} from '@heroicons/react/24/outline';
import { ChartBarSquareIcon } from '@heroicons/react/24/outline';
import { isAdmin } from '../../services/auth';
import { getProperties, updateProperty, deleteProperty, getPricePerM2Data } from '../../services/admin';
import { countries } from '../../utils/helpers';
import MaterialTable from '../../components/admin/MaterialTable';
import Chart, { prepareBarChartData, prepareDoughnutChartData, prepareLineChartData } from '../../components/admin/Chart';
import StatCard from '../../components/admin/StatCard';

const PROPERTY_TYPES = ['House', 'Apartment', 'Land', 'Warehouse', 'Office', 'Building'];

const PropertyManagement = () => {
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editProperty, setEditProperty] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [propertyTypesData, setPropertyTypesData] = useState(null);
  const [priceRangeData, setPropertyPriceData] = useState(null);
  const [propertyStatusData, setPropertyStatusData] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [pricePerM2Data, setPricePerM2Data] = useState(null);
  const [timeRange, setTimeRange] = useState('month');
  const [refreshTrigger, setRefreshTrigger] = useState(0); // Add this for chart responsiveness
  
  // Filters state
  const [filters, setFilters] = useState({
    country: '',
    city: '',
    neighborhood: '',
    propertyType: '',
    postType: '',
    status: ''
  });
  const [showFilters, setShowFilters] = useState(false);

  // Cities based on selected country
  const [availableCities, setAvailableCities] = useState([]);
  // Neighborhoods in data
  const [availableNeighborhoods, setAvailableNeighborhoods] = useState([]);

  // Helper function to get date X days/months/years ago
  const getDateBefore = (days = 0, months = 0, years = 0) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    date.setMonth(date.getMonth() - months);
    date.setFullYear(date.getFullYear() - years);
    return date;
  };
  
  // Helper to format date as YYYY-MM-DD
  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };
  
  // Helper function to apply data continuity by carrying forward the last known value
  const applyDataContinuity = (data) => {
    if (!data || data.length === 0) return [];
    
    const result = [...data];
    let lastValidValue = null;
    
    // First find the first valid value
    for (let i = 0; i < result.length; i++) {
      if (result[i] && result[i] > 0) {
        lastValidValue = result[i];
        break;
      }
    }
    
    // Apply continuity - replace zeros/nulls with the last known value
    for (let i = 0; i < result.length; i++) {
      if (!result[i] || result[i] === 0) {
        if (lastValidValue !== null) {
          result[i] = lastValidValue;
        }
      } else {
        // Update the last valid value
        lastValidValue = result[i];
      }
    }
    
    return result;
  };
  
  // Generate time periods based on selected range
  const generateTimePeriods = (range) => {
    const periods = [];
    const labels = [];
    
    if (range === 'week') {
      // Last 7 days
      for (let i = 6; i >= 0; i--) {
        const date = getDateBefore(i);
        periods.push({
          start: formatDate(date),
          end: formatDate(date),
          label: date.toLocaleDateString('en-US', { weekday: 'short' }),
          simulatedPropertyCount: 30 + Math.floor(Math.random() * 10) // Simulate different property counts per day
        });
        labels.push(date.toLocaleDateString('en-US', { weekday: 'short' }));
      }
    } else if (range === 'month') {
      // Last 4 weeks
      for (let i = 4; i >= 0; i--) {
        const endDate = getDateBefore(i * 7);
        const startDate = getDateBefore(i * 7 + 6);
        const weekLabel = `Week ${4-i}`;
        periods.push({
          start: formatDate(startDate),
          end: formatDate(endDate),
          label: weekLabel,
          simulatedPropertyCount: 120 + Math.floor(Math.random() * 20) // Simulate different property counts per week
        });
        labels.push(weekLabel);
      }
    } else if (range === 'year') {
      // Last 12 months
      for (let i = 11; i >= 0; i--) {
        const date = getDateBefore(0, i);
        const monthLabel = date.toLocaleDateString('en-US', { month: 'short' });
        periods.push({
          start: formatDate(new Date(date.getFullYear(), date.getMonth(), 1)),
          end: formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0)),
          label: monthLabel,
          simulatedPropertyCount: 500 + Math.floor(Math.random() * 50) // Simulate different property counts per month
        });
        labels.push(monthLabel);
      }
    }
    
    return { periods, labels };
  };
  
  // Function to generate dashboard charts and stats
  const generatePropertyDashboards = (propertyData) => {
    // Property types chart
    const propertyTypes = {};
    propertyData.forEach(property => {
      if (!property.property_type) return;
      propertyTypes[property.property_type] = (propertyTypes[property.property_type] || 0) + 1;
    });
    
    setPropertyTypesData(prepareDoughnutChartData(
      Object.keys(propertyTypes),
      Object.values(propertyTypes)
    ));
    
    // Price range chart
    const priceRanges = {
      '< 50k': 0,
      '50k-100k': 0,
      '100k-250k': 0,
      '250k-500k': 0,
      '500k+': 0
    };
    
    propertyData.forEach(property => {
      const price = property.price || 0;
      if (price < 50000) priceRanges['< 50k']++;
      else if (price < 100000) priceRanges['50k-100k']++;
      else if (price < 250000) priceRanges['100k-250k']++;
      else if (price < 500000) priceRanges['250k-500k']++;
      else priceRanges['500k+']++;
    });
    
    setPropertyPriceData(prepareBarChartData(
      Object.keys(priceRanges),
      [{ label: 'Properties by Price', data: Object.values(priceRanges) }]
    ));
    
    // Property status chart
    const activeProperties = propertyData.filter(p => p.active).length;
    const inactiveProperties = propertyData.length - activeProperties;
    
    setPropertyStatusData(prepareDoughnutChartData(
      ['Active', 'Inactive'],
      [activeProperties, inactiveProperties]
    ));
    
    // Location data chart
    const locations = {};
    propertyData.forEach(property => {
      const location = property.city || 'Unknown';
      locations[location] = (locations[location] || 0) + 1;
    });
    
    // Sort by count and take top 5
    const topLocations = Object.entries(locations)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5);
    
    setLocationData(prepareBarChartData(
      topLocations.map(item => item[0]),
      [{ label: 'Properties by Location', data: topLocations.map(item => item[1]) }]
    ));
    
    // Fetch real price per m² data from API
    const fetchPricePerM2Data = async () => {
      try {
        // Create filter object from filters state
        const filterParams = {};
        if (filters.country) filterParams.country = filters.country;
        if (filters.city) filterParams.city = filters.city;
        if (filters.neighborhood) filterParams.neighborhood = filters.neighborhood;
        if (filters.postType) filterParams.postType = filters.postType;
        if (filters.propertyType === 'Land') filterParams.propertyType = 'Land';
        
        // Only fetch data for Land property type
        if (filters.propertyType && filters.propertyType !== 'Land') {
          // If a non-Land property type is selected, show empty chart
          setPricePerM2Data(prepareLineChartData(
            [], [{ label: 'Avg. Price/m²', data: [], fill: false }]
          ));
          return;
        }
        
        // Fetch price per m² data from API
        const { labels, data, isRealData } = await getPricePerM2Data(timeRange, filterParams);
        
        // Create the pointStyles array to mark real vs carried-forward data points
        const pointStyles = isRealData.map(isReal => isReal ? 'circle' : 'triangle');
        const pointBackgroundColors = isRealData.map(isReal => isReal ? 
          'rgba(79, 70, 229, 1)' : 'rgba(209, 213, 219, 1)');
        
        // Get CSS variables for responsive sizing if available
        const getResponsiveValue = (property, fallback) => {
          if (typeof window !== 'undefined' && window.getComputedStyle) {
            const value = getComputedStyle(document.documentElement).getPropertyValue(property);
            return value ? parseFloat(value.trim()) : fallback;
          }
          return fallback;
        };
        
        // Set line chart data with 7-day rolling averages
        setPricePerM2Data(prepareLineChartData(
          labels,
          [{ 
            label: 'Avg. Price/m² (7-day Rolling Average)', 
            data: data,
            fill: false,
            pointStyle: pointStyles,
            pointBackgroundColor: pointBackgroundColors,
            pointBorderColor: pointBackgroundColors,
            // Let CSS variables handle point sizing for responsiveness
            // Don't set explicit values here to allow our responsive
            // system to take precedence
          }]
        ));
      } catch (error) {
        console.error('Error fetching price per m² data:', error);
        // If there's an error, set empty chart data
        setPricePerM2Data(prepareLineChartData(
          [], [{ label: 'Avg. Price/m²', data: [], fill: false }]
        ));
        console.error('Error details:', error);
      }
    };
    
    // Call the fetch function
    fetchPricePerM2Data();
  };

  // Function to load neighborhoods from JSON file
  const loadNeighborhoods = async (city) => {
    if (!city) {
      setAvailableNeighborhoods([]);
      return;
    }
    
    try {
      // For Yaoundé, use yaounde.json file (without accent)
      const cityFileName = city === 'Yaoundé' ? 'yaounde' : city.toLowerCase();
      const response = await fetch(`/data/neighborhoods/cameroon/${cityFileName}.json`);
      
      if (!response.ok) {
        console.error(`Failed to load neighborhoods for ${city}`);
        return;
      }
      
      const data = await response.json();
      const neighborhoodNames = Object.keys(data).sort();
      setAvailableNeighborhoods(neighborhoodNames);
    } catch (err) {
      console.error(`Error loading neighborhoods for ${city}:`, err);
      setAvailableNeighborhoods([]);
    }
  };

  // Add an effect to handle window resize and orientation changes for chart responsiveness
  useEffect(() => {
    // Throttle function to prevent excessive updates
    const throttle = (func, delay) => {
      let lastCall = 0;
      return function(...args) {
        const now = new Date().getTime();
        if (now - lastCall < delay) {
          return;
        }
        lastCall = now;
        return func(...args);
      };
    };
    
    // Function to handle window resize events with throttling
    const handleResize = throttle(() => {
      console.log('Window resized or orientation changed - updating charts');
      // Force refresh of all charts when window size or orientation changes
      if (properties.length > 0) {
        // Instead of trying to regenerate here, we'll trigger a refresh 
        // that will cause the effect hook to run, which has access to filterProperties
        setRefreshTrigger(prev => prev + 1);
      }
    }, 250); // Throttle to max once per 250ms
    
    // Add event listeners for both resize and orientation change
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    
    // Force an initial update
    handleResize();
    
    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [properties]);

  useEffect(() => {
    // Redirect if not admin
    if (!isAdmin()) {
      navigate('/');
      return;
    }

    const fetchProperties = async () => {
      try {
        setLoading(true);
        const data = await getProperties();
        setProperties(data);
        
        // Generate dashboard data for all properties initially
        console.log('Initial data load, all properties:', data.length);
        generatePropertyDashboards(data);
      } catch (err) {
        console.error('Error fetching properties:', err);
        setError('Failed to load properties');
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, [navigate]);
  
  // Update charts when filters, time range, or refreshTrigger changes
  useEffect(() => {
    if (properties.length > 0) {
      const filteredData = filterProperties(properties);
      console.log('Applying filters for analytics. Filtered properties:', filteredData.length);
      generatePropertyDashboards(filteredData);
    }
  }, [filters, properties, timeRange, refreshTrigger]);

  // Update available cities when country changes
  useEffect(() => {
    if (filters.country && countries[filters.country]) {
      const countryCities = Object.keys(countries[filters.country].cities);
      setAvailableCities(countryCities);
      
      // Clear city if not in this country
      if (filters.city && !countryCities.includes(filters.city)) {
        setFilters(prev => ({ ...prev, city: '' }));
      }
    } else {
      setAvailableCities([]);
    }
  }, [filters.country]);
  
  // Load neighborhoods when city changes
  useEffect(() => {
    if (filters.city) {
      loadNeighborhoods(filters.city);
    } else {
      setAvailableNeighborhoods([]);
    }
  }, [filters.city]);

  const handleEditClick = (property) => {
    // Open the EditPostPage in a new tab with the property id
    window.open(`/listings/edit/${property.id}`, '_blank');
  };

  const handleDeleteClick = (propertyId) => {
    setConfirmDelete(propertyId);
  };

  const handleViewProperty = (propertyId, cityCountry) => {
    window.open(`/search?cityCountry=${encodeURIComponent(cityCountry)}&listingId=${propertyId}`, '_blank');
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditProperty(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
    
    // Log filter change for debugging
    console.log(`Filter changed: ${name} = ${value}`);
  };

  const resetFilters = () => {
    setFilters({
      country: '',
      city: '',
      neighborhood: '',
      propertyType: '',
      postType: '',
      status: ''
    });
    
    // Immediately update charts with all properties
    if (properties.length > 0) {
      console.log('Resetting filters, showing all properties:', properties.length);
      generatePropertyDashboards(properties);
    }
  };

  const handleSave = async () => {
    try {
      await updateProperty(editProperty.id, editProperty);
      setProperties(properties.map(property => 
        property.id === editProperty.id ? editProperty : property
      ));
      setEditProperty(null);
    } catch (err) {
      console.error('Error updating property:', err);
      setError('Failed to update property');
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteProperty(confirmDelete);
      setProperties(properties.filter(property => property.id !== confirmDelete));
      setConfirmDelete(null);
    } catch (err) {
      console.error('Error deleting property:', err);
      setError('Failed to delete property');
    }
  };

  const handleCancel = () => {
    setEditProperty(null);
    setConfirmDelete(null);
  };

  // Apply filters to properties
  const filterProperties = (data) => {
    if (!data) return [];
    
    return data.filter(property => {
      if (filters.country && property.country !== filters.country) return false;
      // Handle both forms of Yaoundé (with accented é and without) for filtering
      if (filters.city && 
         !(property.city === filters.city || 
           (filters.city === 'Yaounde' && property.city === 'Yaound?') ||
           (filters.city === 'Yaoundé' && property.city === 'Yaound?'))) return false;
      if (filters.neighborhood && property.neighborhood !== filters.neighborhood) return false;
      if (filters.propertyType && property.property_type !== filters.propertyType) return false;
      if (filters.postType && property.post_type !== filters.postType) return false;
      if (filters.status === 'active' && !property.active) return false;
      if (filters.status === 'inactive' && property.active) return false;
      return true;
    });
  };
  
  // Filtered properties for table and charts
  const filteredProperties = filterProperties(properties);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const columns = [
    { 
      id: 'id',
      label: 'ID', 
      minWidth: 50 
    },
    { 
      id: 'title',
      label: 'Title',
      minWidth: 200,
      render: (row) => (
        <div className="truncate max-w-xs" title={row.title}>
          {row.title}
        </div>
      )
    },
    { 
      id: 'price',
      label: 'Price',
      minWidth: 120,
      render: (row) => `$${row.price?.toLocaleString() || 0}`
    },
    { 
      id: 'property_type',
      label: 'Type',
      minWidth: 120,
      render: (row) => (
        <div>
          <div>{row.property_type}</div>
          <div className="text-xs text-gray-500">({row.post_type})</div>
        </div>
      )
    },
    { 
      id: 'location',
      label: 'Location',
      minWidth: 150,
      render: (row) => (
        <div>
          <div>{row.city}, {row.country}</div>
          {row.neighborhood && (
            <div className="text-xs text-gray-500">{row.neighborhood}</div>
          )}
        </div>
      )
    },
    { 
      id: 'poster_name',
      label: 'Owner',
      minWidth: 120,
      render: (row) => row.fullname || row.poster_name || '—'
    },
    { 
      id: 'active',
      label: 'Status',
      minWidth: 100,
      render: (row) => (
        <span className={`admin-status ${row.active ? 'admin-status-active' : 'admin-status-inactive'}`}>
          {row.active ? 'Active' : 'Inactive'}
        </span>
      )
    },
    { 
      id: 'actions',
      label: 'Actions',
      sortable: false,
      minWidth: 120,
      render: (row) => (
        <div className="admin-actions">
          <button 
            onClick={() => handleViewProperty(row.id, `${row.city}, ${row.country}`)}
            className="admin-btn-icon-only admin-btn-view"
            title="View Property"
          >
            <EyeIcon className="h-5 w-5" />
          </button>
          <button 
            onClick={() => handleEditClick(row)}
            className="admin-btn-icon-only admin-btn-edit"
            title="Edit Property"
          >
            <PencilIcon className="h-5 w-5" />
          </button>
          <button 
            onClick={() => handleDeleteClick(row.id)}
            className="admin-btn-icon-only admin-btn-delete"
            title="Delete Property"
          >
            <TrashIcon className="h-5 w-5" />
          </button>
        </div>
      )
    }
  ];

  const actionButton = (
    <button
      onClick={toggleFilters}
      className="admin-btn admin-btn-secondary"
    >
      <FunnelIcon className="admin-btn-icon" />
      {showFilters ? 'Hide Filters' : 'Show Filters'}
    </button>
  );

  return (
    <AdminLayout 
      title="Property Management" 
      loading={loading} 
      error={error}
      actionButton={actionButton}
    >
      {/* Filters - Moved to top */}
      {showFilters && (
        <div className="admin-filters">
          <h2 className="admin-filters-title">Filters</h2>
          <div className="admin-filters-grid">
            <div className="admin-form-group">
              <label className="admin-form-label">Country</label>
              <select
                name="country"
                value={filters.country}
                onChange={handleFilterChange}
                className="admin-form-select"
              >
                <option value="">All Countries</option>
                {Object.keys(countries).map(country => (
                  <option key={country} value={country}>{country}</option>
                ))}
              </select>
            </div>
            
            <div className="admin-form-group">
              <label className="admin-form-label">City</label>
              <select
                name="city"
                value={filters.city}
                onChange={handleFilterChange}
                className="admin-form-select"
                disabled={!filters.country}
              >
                <option value="">All Cities</option>
                {availableCities.map(city => (
                  <option key={city} value={city}>{city}</option>
                ))}
              </select>
            </div>
            
            <div className="admin-form-group">
              <label className="admin-form-label">Neighborhood</label>
              <select
                name="neighborhood"
                value={filters.neighborhood}
                onChange={handleFilterChange}
                className="admin-form-select"
              >
                <option value="">All Neighborhoods</option>
                {availableNeighborhoods.map(neighborhood => (
                  <option key={neighborhood} value={neighborhood}>{neighborhood}</option>
                ))}
              </select>
            </div>
            
            <div className="admin-form-group">
              <label className="admin-form-label">Property Type</label>
              <select
                name="propertyType"
                value={filters.propertyType}
                onChange={handleFilterChange}
                className="admin-form-select"
              >
                <option value="">All Types</option>
                {PROPERTY_TYPES.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
            
            <div className="admin-form-group">
              <label className="admin-form-label">Post Type</label>
              <select
                name="postType"
                value={filters.postType}
                onChange={handleFilterChange}
                className="admin-form-select"
              >
                <option value="">All</option>
                <option value="Sale">Sale</option>
                <option value="Rent">Rent</option>
              </select>
            </div>
            
            <div className="admin-form-group">
              <label className="admin-form-label">Status</label>
              <select
                name="status"
                value={filters.status}
                onChange={handleFilterChange}
                className="admin-form-select"
              >
                <option value="">All</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </div>
          
          <div className="admin-filters-actions">
            <button
              onClick={resetFilters}
              className="admin-btn admin-btn-secondary"
            >
              Reset Filters
            </button>
          </div>
        </div>
      )}
      
      {/* Stats Row */}
      <div className="admin-stats-grid">
        <StatCard
          title="Total Properties"
          value={filteredProperties.length}
          icon={<HomeIcon className="h-5 w-5" />}
          color="blue"
        />
        <StatCard
          title="Active Properties"
          value={filteredProperties.filter(p => p.active).length}
          icon={<BuildingOfficeIcon className="h-5 w-5" />}
          color="green"
        />
        <StatCard
          title="For Sale"
          value={filteredProperties.filter(p => p.post_type === 'Sale').length}
          icon={<CurrencyDollarIcon className="h-5 w-5" />}
          color="purple"
        />
        <StatCard
          title="For Rent"
          value={filteredProperties.filter(p => p.post_type === 'Rent').length}
          icon={<MapPinIcon className="h-5 w-5" />}
          color="amber"
        />
      </div>

      {/* Dashboard Charts */}
      <div className="admin-dashboard-header">
        <h2 className="admin-dashboard-title">Property Analytics</h2>
        <div className="admin-dashboard-filters">
          <button 
            className={`admin-tab-btn ${timeRange === 'week' ? 'active' : ''}`}
            onClick={() => setTimeRange('week')}
          >
            Week
          </button>
          <button 
            className={`admin-tab-btn ${timeRange === 'month' ? 'active' : ''}`}
            onClick={() => setTimeRange('month')}
          >
            Month
          </button>
          <button 
            className={`admin-tab-btn ${timeRange === 'year' ? 'active' : ''}`}
            onClick={() => setTimeRange('year')}
          >
            Year
          </button>
        </div>
      </div>

      <div className="admin-charts-grid">
        {/* Price per m² Chart */}
        <div className="admin-chart-card" style={{ gridColumn: '1 / -1' }}>
          <h3 className="admin-chart-title">
            <ChartBarSquareIcon className="h-5 w-5" />
            Average Price per m² for Land Properties
          </h3>
          {pricePerM2Data ? (
            <>
              <div className="responsive-chart-container">
                <Chart 
                  type="line" 
                  data={pricePerM2Data}
                  height={250}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: function(context) {
                            return `${context.dataset.label}: ${context.raw.toLocaleString()} CFA/m²`;
                          }
                        },
                        titleFont: {
                          size: window.innerWidth < 768 ? 12 : 14
                        },
                        bodyFont: {
                          size: window.innerWidth < 768 ? 11 : 13
                        },
                        padding: window.innerWidth < 768 ? 8 : 12
                      },
                      legend: {
                        position: window.innerWidth < 768 ? 'bottom' : 'top',
                        labels: {
                          boxWidth: window.innerWidth < 768 ? 12 : 40,
                          font: {
                            size: window.innerWidth < 768 ? 10 : 12
                          }
                        }
                      }
                    },
                    scales: {
                      x: {
                        ticks: {
                          autoSkip: true,
                          maxRotation: 45,
                          minRotation: 45,
                          font: {
                            size: window.innerWidth < 768 ? 9 : 11
                          }
                        },
                        grid: {
                          display: window.innerWidth >= 768, // Hide grid on mobile
                          color: 'rgba(209, 213, 219, 0.3)'
                        }
                      },
                      y: {
                        beginAtZero: false,
                        grace: '5%', // Add a little extra space at the top
                        ticks: {
                          callback: function(value) {
                            // Shorter labels on mobile
                            if (window.innerWidth < 768) {
                              return value >= 1000000 
                                ? (value / 1000000).toFixed(1) + 'M' 
                                : value >= 1000 
                                  ? (value / 1000).toFixed(0) + 'k'
                                  : value;
                            }
                            return value.toLocaleString() + ' CFA/m²';
                          },
                          font: {
                            size: window.innerWidth < 768 ? 9 : 11
                          }
                        },
                        grid: {
                          color: 'rgba(209, 213, 219, 0.3)'
                        },
                        // Make the y-axis adapt to the data range
                        suggestedMin: function(context) {
                          if (context.chart.data.datasets[0].data.length === 0) return 0;
                          const values = context.chart.data.datasets[0].data.filter(v => v > 0);
                          if (values.length === 0) return 0;
                          const min = Math.min(...values);
                          return Math.max(0, min * 0.9); // 10% below minimum, but not below zero
                        },
                        suggestedMax: function(context) {
                          if (context.chart.data.datasets[0].data.length === 0) return 5000000;
                          const values = context.chart.data.datasets[0].data;
                          const max = Math.max(...values);
                          return max * 1.1; // 10% above maximum
                        }
                      }
                    },
                    elements: {
                      point: {
                        radius: `var(--chart-point-radius)`,
                        hoverRadius: `var(--chart-point-hover-radius)`
                      },
                      line: {
                        borderWidth: `var(--chart-line-width)`
                      }
                    }
                  }}
                />
              </div>
              <div className="admin-chart-info mt-2 text-sm text-gray-500">
                <p>
                  <span className="inline-block h-3 w-3 rounded-full bg-indigo-600 mr-1"></span> 
                  Real data points (7-day rolling average)
                  <span className="inline-block h-0 w-0 border-solid border-l-[6px] border-r-[6px] border-b-[9px] border-l-transparent border-r-transparent border-b-gray-400 ml-4 mr-1"></span> 
                  Carried-forward data points (when no new data available)
                </p>
                <p className="text-xs mt-1">
                  Chart shows {timeRange === 'week' ? '7 days' : timeRange === 'month' ? '30 days' : '360 days'} 
                  of data, with each point representing a 7-day rolling average to reduce fluctuations.
                </p>
              </div>
            </>
          ) : (
            <div className="admin-chart-loading">
              <p>Loading chart data...</p>
            </div>
          )}
          {filters.propertyType && filters.propertyType !== 'Land' && (
            <div className="admin-chart-note">
              <p>This chart only shows data for Land properties. Please select Land property type to see price per m² data.</p>
            </div>
          )}
        </div>
        
        {/* Property Types Chart */}
        <div className="admin-chart-card">
          <h3 className="admin-chart-title">
            <ChartPieIcon className="h-5 w-5" />
            Properties by Type
          </h3>
          {propertyTypesData ? (
            <Chart 
              type="doughnut" 
              data={propertyTypesData}
              height={300}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: window.innerWidth < 768 ? 'bottom' : 'right',
                    labels: {
                      boxWidth: window.innerWidth < 768 ? 12 : 20,
                      font: {
                        size: window.innerWidth < 768 ? 10 : 12
                      }
                    }
                  },
                  tooltip: {
                    titleFont: {
                      size: window.innerWidth < 768 ? 12 : 14
                    },
                    bodyFont: {
                      size: window.innerWidth < 768 ? 11 : 13
                    },
                    padding: window.innerWidth < 768 ? 8 : 12
                  }
                }
              }}
            />
          ) : (
            <div className="admin-chart-loading">
              <p>Loading chart data...</p>
            </div>
          )}
        </div>
        
        {/* Price Range Chart */}
        <div className="admin-chart-card">
          <h3 className="admin-chart-title">
            <ChartBarIcon className="h-5 w-5" />
            Properties by Price Range
          </h3>
          {priceRangeData ? (
            <Chart 
              type="bar" 
              data={priceRangeData}
              height={300}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: window.innerWidth >= 480,
                    position: window.innerWidth < 768 ? 'bottom' : 'top',
                    labels: {
                      boxWidth: window.innerWidth < 768 ? 12 : 20,
                      font: {
                        size: window.innerWidth < 768 ? 10 : 12
                      }
                    }
                  },
                  tooltip: {
                    titleFont: {
                      size: window.innerWidth < 768 ? 12 : 14
                    },
                    bodyFont: {
                      size: window.innerWidth < 768 ? 11 : 13
                    }
                  }
                },
                scales: {
                  x: {
                    ticks: {
                      font: {
                        size: window.innerWidth < 768 ? 9 : 11
                      }
                    },
                    grid: {
                      display: window.innerWidth >= 768
                    }
                  },
                  y: {
                    ticks: {
                      font: {
                        size: window.innerWidth < 768 ? 9 : 11
                      }
                    },
                    grid: {
                      color: 'rgba(209, 213, 219, 0.3)'
                    }
                  }
                }
              }}
            />
          ) : (
            <div className="admin-chart-loading">
              <p>Loading chart data...</p>
            </div>
          )}
        </div>
        
        {/* Status Chart */}
        <div className="admin-chart-card">
          <h3 className="admin-chart-title">
            <ChartPieIcon className="h-5 w-5" />
            Listing Status
          </h3>
          {propertyStatusData ? (
            <Chart 
              type="doughnut" 
              data={propertyStatusData}
              height={300}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: window.innerWidth < 768 ? 'bottom' : 'right',
                    labels: {
                      boxWidth: window.innerWidth < 768 ? 12 : 20,
                      font: {
                        size: window.innerWidth < 768 ? 10 : 12
                      }
                    }
                  },
                  tooltip: {
                    titleFont: {
                      size: window.innerWidth < 768 ? 12 : 14
                    },
                    bodyFont: {
                      size: window.innerWidth < 768 ? 11 : 13
                    },
                    padding: window.innerWidth < 768 ? 8 : 12
                  }
                }
              }}
            />
          ) : (
            <div className="admin-chart-loading">
              <p>Loading chart data...</p>
            </div>
          )}
        </div>
        
        {/* Top Locations Chart */}
        <div className="admin-chart-card">
          <h3 className="admin-chart-title">
            <MapPinIcon className="h-5 w-5" />
            Top Locations
          </h3>
          {locationData ? (
            <Chart 
              type="bar" 
              data={locationData}
              height={300}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: window.innerWidth >= 480,
                    position: window.innerWidth < 768 ? 'bottom' : 'top',
                    labels: {
                      boxWidth: window.innerWidth < 768 ? 12 : 20,
                      font: {
                        size: window.innerWidth < 768 ? 10 : 12
                      }
                    }
                  },
                  tooltip: {
                    titleFont: {
                      size: window.innerWidth < 768 ? 12 : 14
                    },
                    bodyFont: {
                      size: window.innerWidth < 768 ? 11 : 13
                    }
                  }
                },
                scales: {
                  x: {
                    ticks: {
                      font: {
                        size: window.innerWidth < 768 ? 9 : 11
                      }
                    },
                    grid: {
                      display: window.innerWidth >= 768
                    }
                  },
                  y: {
                    ticks: {
                      font: {
                        size: window.innerWidth < 768 ? 9 : 11
                      }
                    },
                    grid: {
                      color: 'rgba(209, 213, 219, 0.3)'
                    }
                  }
                }
              }}
            />
          ) : (
            <div className="admin-chart-loading">
              <p>Loading chart data...</p>
            </div>
          )}
        </div>
      </div>


      {/* Properties Table */}
      <div className="admin-table-card">
        <div className="admin-table-header">
          <h3 className="admin-table-title">
            Properties ({filteredProperties.length})
            <span className="text-sm font-normal text-gray-500 ml-2">
              {`${properties.filter(p => p.active).length} active / ${properties.filter(p => !p.active).length} inactive`}
            </span>
          </h3>
        </div>
        
        <MaterialTable 
          columns={columns} 
          data={filteredProperties} 
          isLoading={loading} 
          showSearch={true}
        />
      </div>

      {/* Edit Property Modal */}
      {editProperty && (
        <div className="admin-modal-backdrop">
          <div className="admin-modal">
            <div className="admin-modal-header">
              <h2 className="admin-modal-title">Edit Property</h2>
              <button 
                className="admin-modal-close"
                onClick={handleCancel}
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
            
            <div className="admin-modal-body">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="admin-form-group">
                  <label className="admin-form-label">Title</label>
                  <input
                    type="text"
                    name="title"
                    value={editProperty.title || ''}
                    onChange={handleInputChange}
                    className="admin-form-input"
                  />
                </div>
                
                <div className="admin-form-group">
                  <label className="admin-form-label">Price</label>
                  <input
                    type="number"
                    name="price"
                    value={editProperty.price || ''}
                    onChange={handleInputChange}
                    className="admin-form-input"
                  />
                </div>
                
                <div className="admin-form-group">
                  <label className="admin-form-label">Size (m²)</label>
                  <input
                    type="number"
                    name="size"
                    value={editProperty.size || ''}
                    onChange={handleInputChange}
                    className="admin-form-input"
                  />
                </div>
                
                <div className="admin-form-group">
                  <label className="admin-form-label">Property Type</label>
                  <select
                    name="property_type"
                    value={editProperty.property_type || ''}
                    onChange={handleInputChange}
                    className="admin-form-select"
                  >
                    {PROPERTY_TYPES.map(type => (
                      <option key={type} value={type}>{type}</option>
                    ))}
                  </select>
                </div>
                
                <div className="admin-form-group">
                  <label className="admin-form-label">Post Type</label>
                  <select
                    name="post_type"
                    value={editProperty.post_type || ''}
                    onChange={handleInputChange}
                    className="admin-form-select"
                  >
                    <option value="Sale">Sale</option>
                    <option value="Rent">Rent</option>
                  </select>
                </div>
                
                <div className="admin-form-group">
                  <label className="admin-form-label">Country</label>
                  <input
                    type="text"
                    name="country"
                    value={editProperty.country || ''}
                    onChange={handleInputChange}
                    className="admin-form-input"
                  />
                </div>
                
                <div className="admin-form-group">
                  <label className="admin-form-label">City</label>
                  <input
                    type="text"
                    name="city"
                    value={editProperty.city || ''}
                    onChange={handleInputChange}
                    className="admin-form-input"
                  />
                </div>
                
                <div className="admin-form-group">
                  <label className="admin-form-label">Neighborhood</label>
                  <input
                    type="text"
                    name="neighborhood"
                    value={editProperty.neighborhood || ''}
                    onChange={handleInputChange}
                    className="admin-form-input"
                  />
                </div>
                
                <div className="admin-form-group flex items-center">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="active"
                      checked={editProperty.active}
                      onChange={handleInputChange}
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded mr-2"
                    />
                    <span className="admin-form-label">Active</span>
                  </label>
                </div>
              </div>
              
              <div className="admin-form-group">
                <label className="admin-form-label">Description</label>
                <textarea
                  name="description"
                  value={editProperty.description || ''}
                  onChange={handleInputChange}
                  rows="4"
                  className="admin-form-textarea"
                ></textarea>
              </div>
            </div>
            
            <div className="admin-modal-footer">
              <button
                onClick={handleCancel}
                className="admin-btn admin-btn-secondary"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="admin-btn admin-btn-primary"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {confirmDelete && (
        <div className="admin-modal-backdrop">
          <div className="admin-modal">
            <div className="admin-modal-header">
              <h2 className="admin-modal-title">Confirm Deletion</h2>
              <button 
                className="admin-modal-close"
                onClick={handleCancel}
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
            
            <div className="admin-modal-body text-center">
              <div className="text-red-600 mb-4">
                <TrashIcon className="h-12 w-12 mx-auto" />
              </div>
              <p className="text-xl font-bold mb-2">Are you sure?</p>
              <p className="mb-4 text-gray-600">
                Are you sure you want to permanently delete this property? This action cannot be undone.
              </p>
            </div>
            
            <div className="admin-modal-footer">
              <button
                onClick={handleCancel}
                className="admin-btn admin-btn-secondary"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteConfirm}
                className="admin-btn admin-btn-danger"
              >
                Delete Property
              </button>
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  );
};

export default PropertyManagement;