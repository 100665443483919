import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getListing, updateListing } from '../services/api';
import { getAuthToken } from '../services/auth';
import { 
  getCoordinates, 
  getCityCountryOptions, 
  calculateTotalPrice, 
  calculatePricePerUnit 
} from '../utils/helpers';
import Toast from '../components/Toast';
import MetaTags from '../components/MetaTags';

const EditPostPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  
  // Initialize with data from location state if available
  const initialListing = state?.listing || null;
  
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    price: '',
    propertyType: '',
    cityCountry: '',
    postType: 'Sale',
    bedrooms: '',
    bathrooms: '',
    sqft: '',
    neighborhood: '',
    address: '',
    lat: '',
    lng: '',
    images: []
  });
  
  const [isPricePerUnit, setIsPricePerUnit] = useState(false);
  const [pricePerUnit, setPricePerUnit] = useState('');
  const [images, setImages] = useState([]); // For newly uploaded images
  const [existingImages, setExistingImages] = useState([]); // For images already on the server
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [showNeighborhoodSuggestions, setShowNeighborhoodSuggestions] = useState(false);
  const [toast, setToast] = useState({ visible: false, message: '', type: '' });
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  const [isLoadingListing, setIsLoadingListing] = useState(!initialListing);

  // Fetch listing data if not provided in state
  useEffect(() => {
    if (initialListing) {
      populateFormData(initialListing);
    } else if (id) {
      fetchListing(id);
    } else {
      navigate('/');
    }
  }, [id, initialListing]);

  const fetchListing = async (listingId) => {
    try {
      setIsLoadingListing(true);
      const response = await getListing(listingId);
      console.log("Listing data received:", response);
      
      if (response) {
        populateFormData(response);
      } else {
        setToast({ visible: true, message: t('listingNotFound'), type: 'error' });
        setTimeout(() => navigate('/'), 2000);
      }
    } catch (error) {
      console.error("Error in fetchListing:", error);
      setToast({ visible: true, message: t('errorFetchingListing'), type: 'error' });
    } finally {
      setIsLoadingListing(false);
    }
  };

  // Function to clean image path from backend URL
  const cleanImagePath = (url) => {
    if (!url) return '';
    
    // Find the index of 'uploads/'
    const uploadsIndex = url.indexOf('uploads/');
    if (uploadsIndex === -1) return url; // If 'uploads/' is not found, return original url
    
    // Return everything from 'uploads/' onwards
    return url.substring(uploadsIndex);
  };

  const populateFormData = (listing) => {
    console.log('Populating form data from listing:', listing);
    
    // Extract latitude and longitude from listing
    // Some APIs return lat/lng, others return latitude/longitude
    const latitude = listing.latitude || listing.lat || '';
    const longitude = listing.longitude || listing.lng || '';
    
    setFormData({
      title: listing.title || '',
      description: listing.description || '',
      price: listing.price || '',
      propertyType: listing.propertyType || '',
      cityCountry: listing.cityCountry || '',
      postType: listing.postType || 'Sale',
      bedrooms: listing.bedrooms || '',
      bathrooms: listing.bathrooms || '',
      sqft: listing.sqft || '',
      neighborhood: listing.neighborhood || '',
      address: listing.address || '',
      // Store as latitude/longitude consistently
      latitude: latitude,
      longitude: longitude,
      images: []
    });

    if (listing.isPricePerUnit) {
      setIsPricePerUnit(true);
      setPricePerUnit(listing.pricePerUnit || '');
    }
    
    // Handle existing images if present
    if (listing.images && listing.images.length > 0) {
      console.log('Listing has existing images:', listing.images);
      // We'll need to load these images differently in the UI
      // since they're already on the server
      setExistingImages(listing.images);
    }
    
    if (listing.cityCountry) {
      fetchNeighborhoods(listing.cityCountry.split(',')[0].trim());
    }
  };

  useEffect(() => {
    if (formData.cityCountry) {
      const city = formData.cityCountry.split(',')[0].trim();
      fetchNeighborhoods(city);
      
      // Set default coordinates based on city
      // This will run when the city changes
      setDefaultCityCoordinates(city);
    }
  }, [formData.cityCountry]);
  
  // Function to set default coordinates based on city selection
  const setDefaultCityCoordinates = async (city) => {
    try {
      const cityFileName = city === 'Yaoundé' ? 'yaounde' : city.toLowerCase();
      const response = await fetch(`/data/neighborhoods/cameroon/${cityFileName}.json`);
      
      if (response.ok) {
        const data = await response.json();
        
        // Look for city coordinates - they are usually available with the city name as key
        if (data[city] && Array.isArray(data[city]) && data[city].length === 2) {
          const [lat, lng] = data[city];
          
          // Update coordinates in form
          setFormData(prev => ({
            ...prev,
            latitude: lat,
            longitude: lng
          }));
          
          console.log(`Set default coordinates for ${city}: [${lat}, ${lng}]`);
        } 
        // Or look for entries with the city name
        else {
          const cityEntry = Object.entries(data).find(
            ([key]) => key.toLowerCase().includes(city.toLowerCase())
          );
          
          if (cityEntry && Array.isArray(cityEntry[1]) && cityEntry[1].length === 2) {
            const [lat, lng] = cityEntry[1];
            
            // Update coordinates in form
            setFormData(prev => ({
              ...prev,
              latitude: lat,
              longitude: lng
            }));
            
            console.log(`Set default coordinates for ${city} through match: [${lat}, ${lng}]`);
          } else {
            console.warn(`No default coordinates found for city: ${city}`);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching city coordinates:', error);
    }
  };

  const fetchNeighborhoods = async (city) => {
    try {
      // Convert city name for special cases
      const cityFileName = city === 'Yaoundé' ? 'yaounde' : city.toLowerCase();
      const response = await fetch(`/data/neighborhoods/cameroon/${cityFileName}.json`);
      
      if (response.ok) {
        const data = await response.json();
        // In the douala.json file, the neighborhoods are keys in the object
        if (Object.keys(data).length > 0 && !data.neighborhoods) {
          // Format from existing JSON files where neighborhoods are keys and coordinates are values
          const neighborhoodNames = Object.keys(data);
          setNeighborhoods(neighborhoodNames);
        } else {
          // Handle the case where neighborhoods might be an array in the JSON
          setNeighborhoods(data.neighborhoods || []);
        }
      } else {
        console.warn(`No neighborhood data found for ${city}`);
        setNeighborhoods([]);
      }
    } catch (error) {
      console.error('Error fetching neighborhoods:', error);
      setNeighborhoods([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    // Handle numeric inputs
    if (['price', 'bedrooms', 'bathrooms', 'sqft'].includes(name)) {
      if (value === '') {
        updatedValue = '';
      } else {
        const numValue = parseFloat(value);
        if (isNaN(numValue) || numValue < 0) {
          return;
        }
        updatedValue = name === 'price' ? numValue : Math.floor(numValue);
      }
    }

    // Reset bedroom/bathroom for non-residential properties
    if (name === 'propertyType' && value !== 'House' && value !== 'Apartment') {
      setFormData(prev => ({
        ...prev,
        [name]: updatedValue,
        bedrooms: '',
        bathrooms: ''
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: updatedValue
      }));
    }

    // Special handling for cityCountry (to fetch neighborhoods)
    if (name === 'cityCountry') {
      setFormData(prev => ({
        ...prev,
        cityCountry: updatedValue,
        neighborhood: ''
      }));
    }

    // Handle neighborhood input - show suggestions and filter
    if (name === 'neighborhood') {
      // Show the suggestions if they're typing in the neighborhood field
      if (value && neighborhoods.length > 0) {
        setShowNeighborhoodSuggestions(true);
      } else {
        setShowNeighborhoodSuggestions(false);
      }
    }
  };

  const handlePricePerUnitChange = (e) => {
    const { checked } = e.target;
    setIsPricePerUnit(checked);
    if (!checked) {
      setPricePerUnit('');
    } else if (formData.price && formData.sqft) {
      setPricePerUnit(calculatePricePerUnit(formData.price, formData.sqft));
    }
  };

  const handlePricePerUnitInputChange = (e) => {
    const value = e.target.value;
    if (value === '' || (!isNaN(parseFloat(value)) && parseFloat(value) >= 0)) {
      setPricePerUnit(value);
      if (value && formData.sqft) {
        setFormData(prev => ({
          ...prev,
          price: calculateTotalPrice(value, formData.sqft)
        }));
      }
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    
    // Validate file size (max 5MB)
    const validFiles = files.filter(file => {
      if (file.size > 5 * 1024 * 1024) {
        setToast({
          visible: true,
          message: t('fileTooLarge', { filename: file.name }),
          type: 'error'
        });
        return false;
      }
      
      // Validate file type
      const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
      if (!acceptedTypes.includes(file.type)) {
        setToast({
          visible: true,
          message: t('invalidFileType', { filename: file.name }),
          type: 'error'
        });
        return false;
      }
      
      return true;
    });
    
    if (validFiles.length > 0) {
      setImages(prev => [...prev, ...validFiles]);
    }
  };

  const removeImage = (index) => {
    setImages(prev => prev.filter((_, i) => i !== index));
  };
  
  const removeExistingImage = (index) => {
    setExistingImages(prev => prev.filter((_, i) => i !== index));
  };

  const handleGetLocation = async () => {
    setIsLoadingMap(true);
    try {
      const position = await getCoordinates();
      if (position) {
        setFormData(prev => ({
          ...prev,
          latitude: position.lat,
          longitude: position.lng
        }));
      }
    } catch (error) {
      setToast({
        visible: true,
        message: t('geolocationError'),
        type: 'error'
      });
    } finally {
      setIsLoadingMap(false);
    }
  };

  const handleNeighborhoodFocus = () => {
    // When focusing on the field, show suggestions only if we have neighborhoods
    if (neighborhoods.length > 0) {
      setShowNeighborhoodSuggestions(true);
    }
  };
  
  // This handles clicking outside the neighborhood field to close suggestions
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showNeighborhoodSuggestions) {
        const neighborhoodField = document.getElementById('neighborhood');
        const neighborhoodSuggestions = document.querySelector('.neighborhood-suggestions');
        
        if (neighborhoodField && neighborhoodSuggestions && 
            !neighborhoodField.contains(event.target) && 
            !neighborhoodSuggestions.contains(event.target)) {
          setShowNeighborhoodSuggestions(false);
        }
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showNeighborhoodSuggestions]);

  const selectNeighborhood = async (neighborhood) => {
    // Update the neighborhood name in the form
    setFormData(prev => ({
      ...prev,
      neighborhood
    }));
    
    // Get coordinates for the selected neighborhood
    if (formData.cityCountry) {
      try {
        const city = formData.cityCountry.split(',')[0].trim();
        const cityFileName = city === 'Yaoundé' ? 'yaounde' : city.toLowerCase();
        const response = await fetch(`/data/neighborhoods/cameroon/${cityFileName}.json`);
        
        if (response.ok) {
          const data = await response.json();
          
          // Check if neighborhood coordinates exist
          if (data[neighborhood] && Array.isArray(data[neighborhood]) && data[neighborhood].length === 2) {
            const [lat, lng] = data[neighborhood];
            
            // Update coordinates in form
            setFormData(prev => ({
              ...prev,
              neighborhood,
              latitude: lat,
              longitude: lng
            }));
            
            console.log(`Updated coordinates for ${neighborhood}: [${lat}, ${lng}]`);
          } else {
            console.warn(`No coordinates found for neighborhood: ${neighborhood}`);
          }
        }
      } catch (error) {
        console.error('Error fetching neighborhood coordinates:', error);
      }
    }
    
    setShowNeighborhoodSuggestions(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const token = getAuthToken();
      if (!token) {
        setToast({
          visible: true,
          message: t('notAuthenticated'),
          type: 'error'
        });
        return;
      }
      
      console.log("Preparing form data for submission");
      const formDataObj = new FormData();
      
      // Handle cityCountry field
      if (formData.cityCountry) {
        const [city, country] = formData.cityCountry.split(', ');
        formDataObj.append('city', city);
        formDataObj.append('country', country);
      }
      
      // Map client-side field names to server-side field names
      const fieldMapping = {
        title: 'title',
        description: 'description',
        price: 'price',
        propertyType: 'propertyType',
        postType: 'postType',
        bedrooms: 'bedrooms',
        bathrooms: 'bathrooms',
        sqft: 'sqft',
        neighborhood: 'neighborhood',
        address: 'address',
        latitude: 'latitude',
        longitude: 'longitude'
      };
      
      // Append all form fields with correct field names for the server
      Object.entries(formData).forEach(([key, value]) => {
        if (key !== 'images' && key !== 'cityCountry' && value !== '') {
          const serverField = fieldMapping[key] || key;
          formDataObj.append(serverField, value);
          console.log(`Added field ${serverField}:`, value);
        }
      });
      
      // Append price per unit data if enabled
      if (isPricePerUnit) {
        formDataObj.append('isPricePerUnit', true);
        formDataObj.append('pricePerUnit', pricePerUnit);
      }
      
      // Handle existing images - map to get clean paths
      if (existingImages.length > 0) {
        console.log("Processing existing images:", existingImages.length);
        existingImages.forEach(imageUrl => {
          const cleanPath = cleanImagePath(imageUrl);
          formDataObj.append('existingImages', cleanPath);
          console.log("Added existing image path:", cleanPath);
        });
      } else {
        // If we had images before but now they're all removed, 
        // explicitly tell the server to remove all images
        formDataObj.append('existingImages', '');
        console.log("Signaling to remove all existing images");
      }
      
      // Append new images
      if (images.length > 0) {
        console.log("Adding new images:", images.length);
        images.forEach(image => {
          formDataObj.append('images', image);
        });
      }
      
      console.log("Submitting update with token:", token ? "Token exists" : "No token");
      const response = await updateListing(id, formDataObj, token);
      console.log("Update response:", response);
      
      if (response && (response.success || response.id || response.status === 'success')) {
        setToast({
          visible: true,
          message: t('listingUpdatedSuccessfully'),
          type: 'success'
        });
        
        // Navigate back after successful update
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      } else {
        throw new Error(response?.message || t('unknownError'));
      }
    } catch (error) {
      console.error("Error updating listing:", error);
      setToast({
        visible: true,
        message: error.message || t('errorUpdatingListing'),
        type: 'error'
      });
    }
  };
  
  const handleCancel = () => {
    navigate(-1);
  };

  if (isLoadingListing) {
    return <div className="page-container loading">{t('loading')}...</div>;
  }

  return (
    <div className="page-container edit-post-page">
      <MetaTags title={t('Edit Listing')} />
      <div className="page-header">
        <h1>{t('Edit Listing')}</h1>
        <button onClick={handleCancel} className="back-button">
          {t('back')}
        </button>
      </div>
      
      <div className="edit-post-content">
        <form onSubmit={handleSubmit} className="edit-post-form">
          <div className="form-section">
            <h2>{t('Basic Info')}</h2>
            
            <div className="form-group">
              <label htmlFor="title">{t('title')} *</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="description">{t('description')} *</label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                rows="4"
                required
              />
            </div>
            
            <div className="form-group">
              <label>{t('Post Type')} *</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="postType"
                    value="Sale"
                    checked={formData.postType === 'Sale'}
                    onChange={handleChange}
                  />
                  {t('sale')}
                </label>
                <label>
                  <input
                    type="radio"
                    name="postType"
                    value="Rent"
                    checked={formData.postType === 'Rent'}
                    onChange={handleChange}
                  />
                  {t('rent')}
                </label>
              </div>
            </div>
            
            <div className="form-group">
              <label htmlFor="propertyType">{t('Property Type')} *</label>
              <select
                id="propertyType"
                name="propertyType"
                value={formData.propertyType}
                onChange={handleChange}
                required
              >
                <option value="">{t('select')}</option>
                <option value="House">{t('house')}</option>
                <option value="Apartment">{t('apartment')}</option>
                <option value="Land">{t('land')}</option>
              </select>
            </div>
          </div>
          
          <div className="form-section">
            <h2>{t('Pricing')}</h2>
            
            <div className="form-group">
              <label htmlFor="price">{t('price')} (FCFA) *</label>
              <input
                type="number"
                id="price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === 'e' || e.key === '-' || e.key === '+') {
                    e.preventDefault();
                  }
                }}
                min="0"
                required
              />
            </div>
            
            <div className="form-group checkbox-group">
              <label>
                <input
                  type="checkbox"
                  checked={isPricePerUnit}
                  onChange={handlePricePerUnitChange}
                />
                {t('Specify Price Per Unit')}
              </label>
            </div>
            
            {isPricePerUnit && (
              <div className="form-group">
                <label htmlFor="pricePerUnit">{t('Price Per Square Meter')} (FCFA)</label>
                <input
                  type="number"
                  id="pricePerUnit"
                  value={pricePerUnit}
                  onChange={handlePricePerUnitInputChange}
                  onKeyDown={(e) => {
                    if (e.key === 'e' || e.key === '-' || e.key === '+') {
                      e.preventDefault();
                    }
                  }}
                  min="0"
                />
              </div>
            )}
          </div>
          
          <div className="form-section">
            <h2>{t('Properties')}</h2>
            
            {(formData.propertyType === 'House' || formData.propertyType === 'Apartment') && (
              <>
                <div className="form-group">
                  <label htmlFor="bedrooms">{t('bedrooms')}</label>
                  <input
                    type="number"
                    id="bedrooms"
                    name="bedrooms"
                    value={formData.bedrooms}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === 'e' || e.key === '-' || e.key === '+' || e.key === '.') {
                        e.preventDefault();
                      }
                    }}
                    min="0"
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="bathrooms">{t('bathrooms')}</label>
                  <input
                    type="number"
                    id="bathrooms"
                    name="bathrooms"
                    value={formData.bathrooms}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === 'e' || e.key === '-' || e.key === '+' || e.key === '.') {
                        e.preventDefault();
                      }
                    }}
                    min="0"
                  />
                </div>
              </>
            )}
            
            <div className="form-group">
              <label htmlFor="sqft">{t('sqft')} (m²) *</label>
              <input
                type="number"
                id="sqft"
                name="sqft"
                value={formData.sqft}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === 'e' || e.key === '-' || e.key === '+') {
                    e.preventDefault();
                  }
                }}
                min="0"
                required
              />
            </div>
          </div>
          
          <div className="form-section">
            <h2>{t('Location')}</h2>
            
            <div className="form-group">
              <label htmlFor="cityCountry">{t('City Country')} *</label>
              <select
                id="cityCountry"
                name="cityCountry"
                value={formData.cityCountry}
                onChange={handleChange}
                required
              >
                <option value="">{t('select')}</option>
                {getCityCountryOptions().map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            
            {formData.cityCountry && (
              <div className="form-group neighborhood-group">
                <label htmlFor="neighborhood">
                  {t('neighborhood')} 
                  <span className="input-help-text">(type or select from suggestions)</span>
                </label>
                <div className="search-input-wrapper">
                  <input
                    type="text"
                    id="neighborhood"
                    name="neighborhood"
                    value={formData.neighborhood}
                    onChange={handleChange}
                    onFocus={handleNeighborhoodFocus}
                    placeholder="Start typing to search..."
                    autoComplete="off"
                  />
                  <span className="search-icon">🔍</span>
                </div>
                
                {showNeighborhoodSuggestions && neighborhoods.length > 0 && (
                  <div className="neighborhood-suggestions">
                    {neighborhoods
                      .filter(neighborhood => 
                        formData.neighborhood 
                          ? neighborhood.toLowerCase().includes(formData.neighborhood.toLowerCase())
                          : true
                      )
                      .map((neighborhood, index) => (
                        <div 
                          key={index} 
                          className="neighborhood-option"
                          onClick={() => selectNeighborhood(neighborhood)}
                        >
                          {neighborhood}
                        </div>
                      ))
                    }
                    {formData.neighborhood && neighborhoods.filter(n => 
                      n.toLowerCase().includes(formData.neighborhood.toLowerCase())
                    ).length === 0 && (
                      <div className="no-matches-message">
                        No matches found. Coordinates will use city center.
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            
            <div className="form-group">
              <label htmlFor="address">{t('address')}</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </div>
            
            <div className="form-group coordinates-group">
              <div className="coordinate-inputs">
                <div>
                  <label htmlFor="latitude">{t('latitude')}</label>
                  <input
                    type="text"
                    id="latitude"
                    name="latitude"
                    value={formData.latitude}
                    readOnly
                    className="readonly-field"
                    placeholder={t('latitude')}
                  />
                </div>
                <div>
                  <label htmlFor="longitude">{t('longitude')}</label>
                  <input
                    type="text"
                    id="longitude"
                    name="longitude"
                    value={formData.longitude}
                    readOnly
                    className="readonly-field"
                    placeholder={t('longitude')}
                  />
                </div>
              </div>
              <button 
                type="button" 
                onClick={handleGetLocation}
                className="location-button"
                disabled={isLoadingMap}
              >
                {isLoadingMap ? t('loading') : t('getCurrentLocation')}
              </button>
              <p className="coordinate-help-text">
                Coordinates are automatically set from your selected neighborhood. You can also use the button above to use your current location.
              </p>
            </div>
          </div>
          
          <div className="form-section">
            <h2>{t('Images')}</h2>
            
            <div className="form-group">
              <label htmlFor="images">{t('uploadImages')}</label>
              <input
                type="file"
                id="images"
                name="images"
                onChange={handleImageChange}
                accept="image/*"
                multiple
              />
              <small>{t('maxFileSize', { size: '5MB' })}</small>
            </div>
            
            {/* Existing Images Section */}
            {existingImages.length > 0 && (
              <div className="existing-images-section">
                <h3>Current Images</h3>
                <div className="image-preview-container">
                  {existingImages.map((imageUrl, index) => (
                    <div key={`existing-${index}`} className="image-preview">
                      <img 
                        src={imageUrl} 
                        alt={`Existing ${index}`}
                        onError={(e) => {
                          console.error(`Error loading image ${index}:`, imageUrl);
                          e.target.src = `/images/${formData.propertyType.toLowerCase()}.png`;
                        }}
                      />
                      <button 
                        type="button" 
                        onClick={() => removeExistingImage(index)}
                        className="remove-image"
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
            
            {/* New Images Section */}
            {images.length > 0 && (
              <div className="new-images-section">
                <h3>New Images to Upload</h3>
                <div className="image-preview-container">
                  {images.map((image, index) => (
                    <div key={`new-${index}`} className="image-preview">
                      <img 
                        src={URL.createObjectURL(image)} 
                        alt={`Preview ${index}`} 
                      />
                      <button 
                        type="button" 
                        onClick={() => removeImage(index)}
                        className="remove-image"
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
            
            {/* Empty state: no images */}
            {existingImages.length === 0 && images.length === 0 && (
              <div className="no-images-message">
                No images have been uploaded yet. Please add at least one image.
              </div>
            )}
          </div>
          
          <div className="form-actions">
            <button type="button" onClick={handleCancel} className="cancel-button">
              {t('cancel')}
            </button>
            <button type="submit" className="submit-button">
              {t('Update Listing')}
            </button>
          </div>
        </form>
      </div>
      
      {toast.visible && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ ...toast, visible: false })}
        />
      )}
    </div>
  );
};

export default EditPostPage;