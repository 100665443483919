import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  HomeIcon, 
  UsersIcon, 
  BuildingOfficeIcon, 
  Cog6ToothIcon,
  ChartBarIcon 
} from '@heroicons/react/24/outline';

const AdminMenu = () => {
  const location = useLocation();
  const path = location.pathname;
  
  const menuItems = [
    { 
      path: '/admin/dashboard', 
      label: 'Dashboard', 
      icon: <ChartBarIcon className="admin-menu-icon" /> 
    },
    { 
      path: '/admin/users', 
      label: 'Users', 
      icon: <UsersIcon className="admin-menu-icon" /> 
    },
    { 
      path: '/admin/properties', 
      label: 'Properties', 
      icon: <BuildingOfficeIcon className="admin-menu-icon" /> 
    },
    { 
      path: '/admin/settings', 
      label: 'Settings', 
      icon: <Cog6ToothIcon className="admin-menu-icon" /> 
    },
    { 
      path: '/', 
      label: 'Back to Site', 
      icon: <HomeIcon className="admin-menu-icon" /> 
    }
  ];
  
  return (
    <div className="admin-sidebar">
      <div className="admin-sidebar-brand">
        <h1>
          <BuildingOfficeIcon />
          <span>Admin Panel</span>
        </h1>
      </div>
      
      <ul className="admin-menu">
        {menuItems.map((item) => (
          <li key={item.path} className="admin-menu-item">
            <Link 
              to={item.path} 
              className={`admin-menu-link ${path === item.path ? 'active' : ''}`}
            >
              {item.icon}
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminMenu;