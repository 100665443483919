import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  UserIcon, 
  UserGroupIcon, 
  UserPlusIcon,
  XMarkIcon,
  PencilIcon,
  KeyIcon
} from '@heroicons/react/24/outline';
import AdminLayout from './Layout';
import MaterialTable from '../../components/admin/MaterialTable';
import Chart, { prepareLineChartData } from '../../components/admin/Chart';
import StatCard from '../../components/admin/StatCard';
import { isAdmin } from '../../services/auth';
import { getUsers, updateUser, updateUserPassword } from '../../services/admin';

const UserManagement = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [passwordModal, setPasswordModal] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [userGrowthData, setUserGrowthData] = useState(null);
  const [timeRange, setTimeRange] = useState('month');

  useEffect(() => {
    // Redirect if not admin
    if (!isAdmin()) {
      navigate('/');
      return;
    }

    const fetchUsers = async () => {
      try {
        setLoading(true);
        const data = await getUsers();
        setUsers(data);
        
        // Prepare user growth chart
        generateUserGrowthChart(data, timeRange);
      } catch (err) {
        console.error('Error fetching users:', err);
        setError('Failed to load users');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [navigate, timeRange]);

  const handleEditClick = (user) => {
    setEditUser({...user});
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditUser(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSave = async () => {
    try {
      await updateUser(editUser.id, editUser);
      setUsers(users.map(user => 
        user.id === editUser.id ? editUser : user
      ));
      setEditUser(null);
    } catch (err) {
      console.error('Error updating user:', err);
      setError('Failed to update user');
    }
  };

  const handleCancel = () => {
    setEditUser(null);
  };
  
  const handlePasswordModalOpen = (user) => {
    setPasswordModal(user);
    setNewPassword('');
    setConfirmPassword('');
    setPasswordError('');
  };
  
  const handlePasswordModalClose = () => {
    setPasswordModal(null);
    setNewPassword('');
    setConfirmPassword('');
    setPasswordError('');
  };
  
  const handlePasswordChange = async () => {
    // Validate passwords
    if (!newPassword) {
      setPasswordError('Password cannot be empty');
      return;
    }
    
    if (newPassword.length < 8) {
      setPasswordError('Password must be at least 8 characters long');
      return;
    }
    
    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }
    
    try {
      await updateUserPassword(passwordModal.id, newPassword);
      handlePasswordModalClose();
      // No need to update users array since password isn't stored in the client
    } catch (err) {
      console.error('Error updating password:', err);
      setPasswordError('Failed to update password. Please try again.');
    }
  };

  // Generate user growth chart data
  const generateUserGrowthChart = (userData, range) => {
    // Get date range
    const now = new Date();
    let startDate;
    let dateFormat;
    let interval;

    switch(range) {
      case 'week':
        startDate = new Date(now);
        startDate.setDate(now.getDate() - 7);
        dateFormat = date => date.toLocaleDateString('en-US', { weekday: 'short' });
        interval = 'day';
        break;
      case 'year':
        startDate = new Date(now);
        startDate.setFullYear(now.getFullYear() - 1);
        dateFormat = date => date.toLocaleDateString('en-US', { month: 'short' });
        interval = 'month';
        break;
      case 'month':
      default:
        startDate = new Date(now);
        startDate.setMonth(now.getMonth() - 1);
        dateFormat = date => date.getDate();
        interval = 'day';
        break;
    }

    // Generate date range labels
    const dateLabels = [];
    const currentDate = new Date(startDate);
    while (currentDate <= now) {
      dateLabels.push(new Date(currentDate));
      
      if (interval === 'day') {
        currentDate.setDate(currentDate.getDate() + 1);
      } else if (interval === 'month') {
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
    }

    // Count users by date
    const usersByDate = {};
    userData.forEach(user => {
      if (!user.created_at) return;
      
      const date = new Date(user.created_at);
      if (date < startDate) return;
      
      let key;
      if (interval === 'day') {
        key = date.toISOString().split('T')[0]; // YYYY-MM-DD
      } else if (interval === 'month') {
        key = `${date.getFullYear()}-${date.getMonth() + 1}`; // YYYY-MM
      }
      
      usersByDate[key] = (usersByDate[key] || 0) + 1;
    });

    // Map data to labels
    const userCountData = dateLabels.map(date => {
      let key;
      if (interval === 'day') {
        key = date.toISOString().split('T')[0]; // YYYY-MM-DD
      } else if (interval === 'month') {
        key = `${date.getFullYear()}-${date.getMonth() + 1}`; // YYYY-MM
      }
      
      return usersByDate[key] || 0;
    });

    // Prepare chart data
    setUserGrowthData(prepareLineChartData(
      dateLabels.map(d => dateFormat(d)),
      [{ label: 'New Users', data: userCountData }]
    ));
  };

  // Calculate user statistics
  const totalUsers = users.length;
  const adminUsers = users.filter(user => user.is_admin).length;
  const regularUsers = totalUsers - adminUsers;
  
  // Calculate user growth
  const getRecentUserGrowth = () => {
    const now = new Date();
    const last30Days = new Date(now);
    last30Days.setDate(now.getDate() - 30);
    
    const last60Days = new Date(now);
    last60Days.setDate(now.getDate() - 60);
    
    const recentUsers = users.filter(user => 
      new Date(user.created_at) >= last30Days
    ).length;
    
    const previousUsers = users.filter(user => 
      new Date(user.created_at) >= last60Days && 
      new Date(user.created_at) < last30Days
    ).length;
    
    if (previousUsers === 0) return { percent: 0, type: 'neutral' };
    
    const growthRate = ((recentUsers - previousUsers) / previousUsers) * 100;
    return {
      percent: Math.abs(growthRate).toFixed(1),
      type: growthRate > 0 ? 'increase' : growthRate < 0 ? 'decrease' : 'neutral'
    };
  };
  
  const growth = getRecentUserGrowth();

  const columns = [
    { id: 'id', label: 'ID' },
    { id: 'username', label: 'Username' },
    { id: 'email', label: 'Email' },
    { id: 'fullname', label: 'Full Name' },
    { 
      id: 'is_admin', 
      label: 'Role',
      render: (row) => (
        <span className={`admin-status ${row.is_admin ? 'admin-status-active' : ''}`}>
          {row.is_admin ? 'Admin' : 'User'}
        </span>
      )
    },
    { 
      id: 'created_at', 
      label: 'Joined',
      render: (row) => new Date(row.created_at).toLocaleDateString()
    },
    {
      id: 'actions',
      label: 'Actions',
      sortable: false,
      render: (row) => (
        <div className="admin-actions">
          <button 
            onClick={() => handleEditClick(row)}
            className="admin-btn-icon-only admin-btn-edit"
            title="Edit User"
          >
            <PencilIcon className="h-5 w-5" />
          </button>
          <button 
            onClick={() => handlePasswordModalOpen(row)}
            className="admin-btn-icon-only admin-btn-secondary"
            title="Change Password"
          >
            <KeyIcon className="h-5 w-5" />
          </button>
        </div>
      )
    }
  ];

  return (
    <AdminLayout title="User Management" loading={loading} error={error}>
      {/* Stats Row */}
      <div className="admin-stats-grid">
        <StatCard
          title="Total Users"
          value={totalUsers}
          icon={<UserGroupIcon />}
          color="blue"
          change={growth.percent}
          changeType={growth.type}
          description="vs previous 30 days"
        />
        <StatCard
          title="Admin Users"
          value={adminUsers}
          icon={<UserIcon />}
          color="purple"
          description={`${((adminUsers / Math.max(totalUsers, 1)) * 100).toFixed(0)}% of total`}
        />
        <StatCard
          title="Regular Users"
          value={regularUsers}
          icon={<UserPlusIcon />}
          color="green"
          description={`${((regularUsers / Math.max(totalUsers, 1)) * 100).toFixed(0)}% of total`}
        />
        <StatCard
          title="Active Last 30 Days"
          value={users.filter(u => new Date(u.last_login) >= new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).length}
          icon={<UserIcon />}
          color="amber"
        />
      </div>

      {/* User Growth Chart */}
      <div className="admin-chart-card mb-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="admin-chart-title">User Growth</h3>
          
          <div className="admin-time-filter">
            {['week', 'month', 'year'].map(range => (
              <button
                key={range}
                onClick={() => setTimeRange(range)}
                className={`admin-time-option ${timeRange === range ? 'active' : ''}`}
              >
                {range.charAt(0).toUpperCase() + range.slice(1)}
              </button>
            ))}
          </div>
        </div>
        
        {userGrowthData ? (
          <Chart 
            type="line" 
            data={userGrowthData}
            height={300}
          />
        ) : (
          <div className="h-[300px] flex items-center justify-center">
            <p className="text-gray-500">Loading chart data...</p>
          </div>
        )}
      </div>

      {/* Users Table */}
      <div className="admin-table-card">
        <div className="admin-table-header">
          <h3 className="admin-table-title">All Users ({users.length})</h3>
        </div>
        
        <MaterialTable 
          columns={columns}
          data={users}
          isLoading={loading}
          showSearch={true}
        />
      </div>

      {/* Edit User Modal */}
      {editUser && (
        <div className="admin-modal-backdrop">
          <div className="admin-modal">
            <div className="admin-modal-header">
              <h2 className="admin-modal-title">Edit User</h2>
              <button 
                className="admin-modal-close"
                onClick={handleCancel}
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
            
            <div className="admin-modal-body">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="admin-form-group">
                  <label className="admin-form-label">Username</label>
                  <input
                    type="text"
                    name="username"
                    value={editUser.username || ''}
                    onChange={handleInputChange}
                    className="admin-form-input"
                    disabled
                  />
                </div>
                
                <div className="admin-form-group">
                  <label className="admin-form-label">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={editUser.email || ''}
                    onChange={handleInputChange}
                    className="admin-form-input"
                  />
                </div>
                
                <div className="admin-form-group">
                  <label className="admin-form-label">Full Name</label>
                  <input
                    type="text"
                    name="fullname"
                    value={editUser.fullname || ''}
                    onChange={handleInputChange}
                    className="admin-form-input"
                  />
                </div>
                
                <div className="admin-form-group flex items-center">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="is_admin"
                      checked={editUser.is_admin}
                      onChange={handleInputChange}
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded mr-2"
                    />
                    <span className="admin-form-label">Admin User</span>
                  </label>
                </div>
              </div>
            </div>
            
            <div className="admin-modal-footer">
              <button
                onClick={handleCancel}
                className="admin-btn admin-btn-secondary"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="admin-btn admin-btn-primary"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
      
      {/* Change Password Modal */}
      {passwordModal && (
        <div className="admin-modal-backdrop">
          <div className="admin-modal">
            <div className="admin-modal-header">
              <h2 className="admin-modal-title">Change Password for {passwordModal.username}</h2>
              <button 
                className="admin-modal-close"
                onClick={handlePasswordModalClose}
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
            
            <div className="admin-modal-body">
              {passwordError && (
                <div className="mb-4 p-2 bg-red-100 text-red-700 rounded border border-red-300">
                  {passwordError}
                </div>
              )}
              
              <div className="grid grid-cols-1 gap-4">
                <div className="admin-form-group">
                  <label className="admin-form-label">New Password</label>
                  <input
                    type="password"
                    name="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="admin-form-input"
                    placeholder="Enter new password"
                  />
                </div>
                
                <div className="admin-form-group">
                  <label className="admin-form-label">Confirm Password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="admin-form-input"
                    placeholder="Confirm new password"
                  />
                </div>
                
                <div className="text-xs text-gray-500 mt-2">
                  Password must be at least 8 characters long.
                </div>
              </div>
            </div>
            
            <div className="admin-modal-footer">
              <button
                onClick={handlePasswordModalClose}
                className="admin-btn admin-btn-secondary"
              >
                Cancel
              </button>
              <button
                onClick={handlePasswordChange}
                className="admin-btn admin-btn-primary"
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  );
};

export default UserManagement;