// Import
import i18n from '../i18n'; // Make sure to import your i18n instance

// Variables from environment
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const API_URL = process.env.REACT_APP_API_URL;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;


// Images
export const default_image = `./images/placeholder_house.jpeg`;
export const house_image = default_image;
export const land_image = `./images/placeholder_land.jpeg`;
export const apartment_image = `./images/placeholder_apartment.jpeg`;
export const DEFAULT_CARD_COUNT = 40;


// Updated default image path
export const DEFAULT_IMAGE = default_image;
const DLA = `./images/cities/douala.jpg`;
const ABJ = `./images/cities/abidjan.jpg`;
const DKR = `./images/cities/dakar.jpg`;
const CTN = `./images/cities/cotonou.jpg`;
const ACC = `./images/cities/accra.webp`;
const BMK = `./images/cities/bamako.jpg`;


// sqft and m2
// Conversion constants
const SQFT_TO_M2 = 0.092903;
const M2_TO_SQFT = 10.7639;
const M2_TO_HECTARES = 0.0001;
const M2_TO_ACRES = 0.000247105;
const HECTARE_THRESHOLD = 10000; // 1 hectare = 10,000 m²
const ACRE_THRESHOLD = 4046.86; // 1 acre = 4046.86 m²


// Format price to locale string with full words for thousands, millions, billions
export const formatPrice = (price, countryCode) => {
  const countryData = Object.values(countries).find(c => c.countryCode === countryCode);
  const currencySymbol = countryData ? countryData.currencySymbol : '$';
  const language = i18n.language;

  // Function to get the translation key based on value (for plural handling)
  const getTranslationKey = (value, base) => {
    // For exact value of 1, use singular form
    if (value === 1) return base;
    // Otherwise use plural form
    return `${base}_plural`;
  };

  // Get translations for current language
  const translations = {
    en: {
      thousand: "Thousand",
      thousand_plural: "Thousands",
      million: "Million",
      million_plural: "Millions",
      billion: "Billion",
      billion_plural: "Billions"
    },
    fr: {
      thousand: "Mille",
      thousand_plural: "Milles",
      million: "Million",
      million_plural: "Millions", 
      billion: "Milliard",
      billion_plural: "Milliards"
    }
  };

  // Get language-specific translations
  const trans = translations[language] || translations.en;

  let formattedPrice;
  if (price >= 1000000000) {
    const billions = price / 1000000000;
    const numericPart = Number.isInteger(billions) ? billions.toFixed(0) : billions.toFixed(1);
    const term = trans[getTranslationKey(billions, 'billion')];
    formattedPrice = `${numericPart} ${term}`;
  } else if (price >= 1000000) {
    const millions = price / 1000000;
    const numericPart = Number.isInteger(millions) ? millions.toFixed(0) : millions.toFixed(1);
    const term = trans[getTranslationKey(millions, 'million')];
    formattedPrice = `${numericPart} ${term}`;
  } else if (price >= 1000) {
    const thousands = price / 1000;
    const numericPart = thousands.toFixed(0);
    const term = trans[getTranslationKey(thousands, 'thousand')];
    formattedPrice = `${numericPart} ${term}`;
  } else {
    formattedPrice = price.toFixed(0);
  }

  // Replace decimal point with comma for French
  if (language === 'fr') {
    formattedPrice = formattedPrice.replace('.', ',');
  }

  return `${currencySymbol} ${formattedPrice}`;
};


// Convert sqft to m2
export const sqftToM2 = (sqft) => {
  return Math.round(sqft * SQFT_TO_M2);
};

// Convert m2 to sqft 
export const m2ToSqft = (m2) => {
  return Math.round(m2 * M2_TO_SQFT);
};

// Convert m2 to hectares
export const m2ToHectares = (m2) => {
  return m2 * M2_TO_HECTARES;
};

// Convert m2 to acres
export const m2ToAcres = (m2) => {
  return m2 * M2_TO_ACRES;
};

// Format square footage based on language and size
export const formatSqft = (m2Value, language) => {
  if (!m2Value) return '0';

  if (language === 'fr') {
    // French format
    if (m2Value >= HECTARE_THRESHOLD) {
      // Convert to hectares if >= 1 hectare
      const hectares = m2ToHectares(m2Value);
      // Format with 2 decimal places if less than 10 hectares, otherwise as integer
      const formattedValue = hectares < 10 
        ? hectares.toFixed(2).replace('.', ',')
        : Math.round(hectares).toLocaleString('fr-FR');
      return `${formattedValue} ha`;
    } else {
      // Keep as square meters
      return `${Math.round(m2Value).toLocaleString('fr-FR')} m²`;
    }
  } else {
    // English format
    const sqftValue = m2ToSqft(m2Value);
    if (sqftValue >= ACRE_THRESHOLD * M2_TO_SQFT) {
      // Convert to acres if >= 1 acre
      const acres = m2ToAcres(m2Value);
      // Format with 2 decimal places if less than 10 acres, otherwise as integer
      const formattedValue = acres < 10 
        ? acres.toFixed(2)
        : Math.round(acres).toLocaleString();
      return `${formattedValue} acres`;
    } else {
      // Keep as square feet
      return `${Math.round(sqftValue).toLocaleString()} sqft`;
    }
  }
};

// Calculate price per square foot/meter
export const calculatePricePerSqft = (price, m2Value, language, countryCode) => {
  if (!price || !m2Value || m2Value === 0) return 'N/A';

  if (language === 'fr') {
    // Calculate price per m²
    const pricePerM2 = price / m2Value;
    // Format with commas for thousands and comma as decimal separator
    return `${Math.round(pricePerM2).toLocaleString('fr-FR')} / m²`;
  } else {
    // Calculate price per sqft
    const sqftValue = m2ToSqft(m2Value);
    const pricePerSqft = price / sqftValue;
    // Format with commas for thousands and period as decimal separator
    return `${Math.round(pricePerSqft).toLocaleString()} / sqft`;
  }
};

// Alias for calculatePricePerSqft for backward compatibility
export const calculatePricePerUnit = calculatePricePerSqft;

// Calculate total price based on price per unit and area
export const calculateTotalPrice = (pricePerUnit, area) => {
  if (!pricePerUnit || !area) return 0;
  return pricePerUnit * area;
};

// Capitalize first letter of string
export const capitalizeFirstLetter = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Format a date in a post-friendly way
export const formatDatePost = (date) => {
  if (!date) return '';
  const d = new Date(date);
  return d.toLocaleDateString(i18n.language === 'fr' ? 'fr-FR' : 'en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

// Format a date as YYYY-MM-DD
export const formatDateSimple = (date) => {
  if (!date) return '';
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Mask email for privacy
export const maskEmail = (email) => {
  if (!email) return '';
  
  // Return null for emails ending with @email.com
  if (email.toLowerCase().endsWith('@email.com')) {
    return null;
  }
  
  const atIndex = email.indexOf('@');
  if (atIndex <= 1) return email; // Don't mask very short usernames
  
  const username = email.slice(0, atIndex);
  const domain = email.slice(atIndex);
  
  const maskedUsername = username.slice(0, Math.min(3, username.length)) + 
                         '*'.repeat(Math.max(0, username.length - 3));
  
  return maskedUsername + domain;
};

// Country data for international formatting
export const countries = {
  "Cameroon": {
    countryCode: "CM",
    phoneCountryCode: "+237",
    currencySymbol: "FCFA",
    picture: DLA,
    cities: {
      "Douala": { 
        coord: { lat: 4.0511, lng: 9.7679 },
        image: DLA,
        neighborhoods: {
          "Akwa": { coord: { lat: 4.0500, lng: 9.6921 } },
          "Bonanjo": { coord: { lat: 4.0372, lng: 9.6858 } },
          "Bonapriso": { coord: { lat: 4.0347, lng: 9.6937 } },
          "Deido": { coord: { lat: 4.0672, lng: 9.6781 } },
          "Bali": { coord: { lat: 4.0591, lng: 9.6851 } },
          "Bonamoussadi": { coord: { lat: 4.0953, lng: 9.7491 } },
          "Makepe": { coord: { lat: 4.0906, lng: 9.7685 } },
          "Ndogbong": { coord: { lat: 4.0694, lng: 9.7566 } },
          "PK": { coord: { lat: 4.0875, lng: 9.8022 } }
        }
      },
      "Yaoundé": {
        coord: { lat: 3.8667, lng: 11.5167 },
        neighborhoods: {
          "Centre": { coord: { lat: 3.8667, lng: 11.5167 } },
          "Bastos": { coord: { lat: 3.8833, lng: 11.5083 } },
          "Nlongkak": { coord: { lat: 3.8917, lng: 11.5278 } },
          "Olembe": { coord: { lat: 3.9083, lng: 11.5250 } },
          "Mbankolo": { coord: { lat: 3.9056, lng: 11.4944 } },
          "Elig-Essono": { coord: { lat: 3.8639, lng: 11.5083 } },
          "Mfandena": { coord: { lat: 3.8500, lng: 11.5278 } },
          "Santa Barbara": { coord: { lat: 3.8278, lng: 11.5361 } },
          "Ngousso": { coord: { lat: 3.8778, lng: 11.5361 } }
        }
      },
      "Bamenda": {
        coord: { lat: 5.9631, lng: 10.1589 },
        neighborhoods: {
          "Commercial Avenue": { coord: { lat: 5.9631, lng: 10.1589 } },
          "Up Station": { coord: { lat: 5.9631, lng: 10.1589 } },
          "Down Town": { coord: { lat: 5.9631, lng: 10.1589 } },
          "Mile 2": { coord: { lat: 5.9631, lng: 10.1589 } }
        }
      },
      "Bafoussam": {
        coord: { lat: 5.4731, lng: 10.4225 },
        neighborhoods: {
          "Centre": { coord: { lat: 5.4731, lng: 10.4225 } },
          "Bafang": { coord: { lat: 5.1617, lng: 10.1783 } },
          "Baleng": { coord: { lat: 5.4731, lng: 10.4225 } },
          "Banengo": { coord: { lat: 5.4731, lng: 10.4225 } }
        }
      },
      "Buea": {
        coord: { lat: 4.1558, lng: 9.2433 },
        neighborhoods: {
          "Great Soppo": { coord: { lat: 4.1558, lng: 9.2433 } },
          "Clerks Quarter": { coord: { lat: 4.1558, lng: 9.2433 } },
          "Bokwangwo": { coord: { lat: 4.1558, lng: 9.2433 } },
          "GRA": { coord: { lat: 4.1558, lng: 9.2433 } },
          "Molyko": { coord: { lat: 4.1558, lng: 9.2433 } }
        }
      }
    }
  },
  "Cote d'Ivoire": {
    countryCode: "CI",
    phoneCountryCode: "+225",
    currencySymbol: "FCFA",
    picture: ABJ,
    cities: {
      "Abidjan": {
        coord: { lat: 5.3364, lng: -4.0267 },
        image: ABJ,
        neighborhoods: {
          "Plateau": { coord: { lat: 5.3364, lng: -4.0267 } },
          "Cocody": { coord: { lat: 5.3739, lng: -3.9703 } },
          "Marcory": { coord: { lat: 5.3031, lng: -3.9794 } },
          "Yopougon": { coord: { lat: 5.3167, lng: -4.1167 } },
          "Bingerville": { coord: { lat: 5.3364, lng: -3.9036 } },
          "Treichville": { coord: { lat: 5.2928, lng: -4.0053 } },
          "Port-Bouët": { coord: { lat: 5.2578, lng: -3.9300 } },
          "Adjamé": { coord: { lat: 5.3569, lng: -4.0244 } },
          "Abobo": { coord: { lat: 5.4269, lng: -4.0347 } }
        }
      },
      "Yamoussoukro": {
        coord: { lat: 6.8276, lng: -5.2893 },
        neighborhoods: {
          "Centre": { coord: { lat: 6.8276, lng: -5.2893 } },
          "Administrative": { coord: { lat: 6.8276, lng: -5.2893 } },
          "Assabou": { coord: { lat: 6.8276, lng: -5.2893 } }
        }
      }
    }
  },
  "Senegal": {
    countryCode: "SN",
    phoneCountryCode: "+221",
    currencySymbol: "FCFA",
    picture: DKR,
    cities: {
      "Dakar": {
        coord: { lat: 14.7645, lng: -17.3660 },
        image: DKR,
        neighborhoods: {
          "Plateau": { coord: { lat: 14.6716, lng: -17.4380 } },
          "Medina": { coord: { lat: 14.6924, lng: -17.4444 } },
          "Fann": { coord: { lat: 14.6891, lng: -17.4662 } },
          "Almadies": { coord: { lat: 14.7432, lng: -17.5139 } },
          "Ngor": { coord: { lat: 14.7503, lng: -17.5122 } },
          "Yoff": { coord: { lat: 14.7582, lng: -17.4875 } },
          "Ouakam": { coord: { lat: 14.7307, lng: -17.4924 } },
          "Mermoz": { coord: { lat: 14.7159, lng: -17.4776 } },
          "Sacré-Cœur": { coord: { lat: 14.7079, lng: -17.4661 } }
        }
      },
      "Saint-Louis": {
        coord: { lat: 16.0326, lng: -16.4818 },
        neighborhoods: {
          "Centre": { coord: { lat: 16.0326, lng: -16.4818 } },
          "Guet Ndar": { coord: { lat: 16.0326, lng: -16.4818 } },
          "Sor": { coord: { lat: 16.0326, lng: -16.4818 } }
        }
      }
    }
  },
  "Benin": {
    countryCode: "BJ",
    phoneCountryCode: "+229",
    currencySymbol: "FCFA",
    picture: CTN,
    cities: {
      "Cotonou": {
        coord: { lat: 6.3676, lng: 2.4583 },
        image: CTN,
        neighborhoods: {
          "Ganhi": { coord: { lat: 6.3676, lng: 2.4583 } },
          "Cadjehoun": { coord: { lat: 6.3676, lng: 2.4583 } },
          "Akpakpa": { coord: { lat: 6.3676, lng: 2.4583 } },
          "Fidjrosse": { coord: { lat: 6.3676, lng: 2.4583 } },
          "Haie Vive": { coord: { lat: 6.3676, lng: 2.4583 } },
          "Jericho": { coord: { lat: 6.3676, lng: 2.4583 } },
          "Zogbo": { coord: { lat: 6.3676, lng: 2.4583 } },
          "Agla": { coord: { lat: 6.3676, lng: 2.4583 } },
          "Aibatin": { coord: { lat: 6.3676, lng: 2.4583 } }
        }
      },
      "Porto-Novo": {
        coord: { lat: 6.4965, lng: 2.6036 },
        neighborhoods: {
          "Centre": { coord: { lat: 6.4965, lng: 2.6036 } },
          "Adjarra": { coord: { lat: 6.4965, lng: 2.6036 } },
          "Ouando": { coord: { lat: 6.4965, lng: 2.6036 } }
        }
      }
    }
  },
  "Ghana": {
    countryCode: "GH",
    phoneCountryCode: "+233",
    currencySymbol: "₵",
    picture: ACC,
    cities: {
      "Accra": {
        coord: { lat: 5.6037, lng: -0.1870 },
        image: ACC,
        neighborhoods: {
          "Osu": { coord: { lat: 5.5557, lng: -0.1807 } },
          "Labone": { coord: { lat: 5.5640, lng: -0.1720 } },
          "Airport Residential Area": { coord: { lat: 5.5897, lng: -0.1779 } },
          "East Legon": { coord: { lat: 5.6356, lng: -0.1548 } },
          "Cantonments": { coord: { lat: 5.5708, lng: -0.1797 } },
          "Adabraka": { coord: { lat: 5.5600, lng: -0.2039 } },
          "Achimota": { coord: { lat: 5.6186, lng: -0.2282 } },
          "Tesano": { coord: { lat: 5.6000, lng: -0.2203 } },
          "Lapaz": { coord: { lat: 5.6114, lng: -0.2464 } }
        }
      },
      "Kumasi": {
        coord: { lat: 6.6885, lng: -1.6244 },
        neighborhoods: {
          "Adum": { coord: { lat: 6.6885, lng: -1.6244 } },
          "Bantama": { coord: { lat: 6.6885, lng: -1.6244 } },
          "Nhyiaeso": { coord: { lat: 6.6885, lng: -1.6244 } }
        }
      }
    }
  },
  "Mali": {
    countryCode: "ML",
    phoneCountryCode: "+223",
    currencySymbol: "FCFA",
    picture: BMK,
    cities: {
      "Bamako": {
        coord: { lat: 12.6392, lng: -8.0029 },
        image: BMK,
        neighborhoods: {
          "Hamdallaye": { coord: { lat: 12.6392, lng: -8.0029 } },
          "Badalabougou": { coord: { lat: 12.6392, lng: -8.0029 } },
          "Hippodrome": { coord: { lat: 12.6392, lng: -8.0029 } },
          "ACI 2000": { coord: { lat: 12.6392, lng: -8.0029 } },
          "Kalaban-Coro": { coord: { lat: 12.6392, lng: -8.0029 } },
          "Magnambougou": { coord: { lat: 12.6392, lng: -8.0029 } },
          "Sotuba": { coord: { lat: 12.6392, lng: -8.0029 } },
          "Korofina": { coord: { lat: 12.6392, lng: -8.0029 } },
          "Djicoroni": { coord: { lat: 12.6392, lng: -8.0029 } }
        }
      },
      "Sikasso": {
        coord: { lat: 11.3167, lng: -5.6667 },
        neighborhoods: {
          "Centre": { coord: { lat: 11.3167, lng: -5.6667 } },
          "Wayerma": { coord: { lat: 11.3167, lng: -5.6667 } },
          "Medine": { coord: { lat: 11.3167, lng: -5.6667 } }
        }
      }
    }
  }
};

// Convert cities to options for select
export const getCityCountryOptions = () => {
  const options = [];
  Object.entries(countries).forEach(([countryName, countryData]) => {
    Object.keys(countryData.cities).forEach(cityName => {
      // Push just the string format (the Search page uses strings, not objects)
      options.push(`${cityName}, ${countryName}`);
    });
  });
  return options;
};

// Get neighborhoods for a city
export const getNeighborhoods = (cityName, countryName) => {
  if (!cityName || !countryName) return [];

  const countryData = countries[countryName];
  if (!countryData) return [];

  const cityData = countryData.cities[cityName];
  if (!cityData || !cityData.neighborhoods) return [];

  return Object.keys(cityData.neighborhoods);
};

// Get coordinates from city, country, and optional neighborhood
export const getCoordinates = (city, country, neighborhood = null) => {
  if (!city || !country) {
    return { lat: 9.1450, lng: 18.4275 }; // Default coordinates (Central Africa)
  }

  // Look up the country data
  const countryData = countries[country];
  if (!countryData) {
    console.log(`Country not found: ${country}`);
    return { lat: 9.1450, lng: 18.4275 };
  }

  // Look up the city data in that country
  const cityData = countryData.cities[city];
  if (!cityData) {
    console.log(`City not found: ${city} in ${country}`);
    return { lat: 9.1450, lng: 18.4275 };
  }

  // If a neighborhood is specified and exists, return its coordinates
  if (neighborhood && cityData.neighborhoods && cityData.neighborhoods[neighborhood]) {
    return cityData.neighborhoods[neighborhood].coord;
  }

  // Otherwise return the city's coordinates
  return cityData.coord;
};

// Extract city and country from combined string ("City, Country")
export const getCityCountry = (cityCountryStr) => {
  if (!cityCountryStr) return { city: '', country: '' };
  
  const parts = cityCountryStr.split(', ');
  if (parts.length !== 2) return { city: '', country: '' };
  
  return {
    city: parts[0],
    country: parts[1]
  };
};

// Check if a value is empty (null, undefined, empty string, empty array, empty object)
export const isEmpty = (value) => {
  return (
    value === null ||
    value === undefined ||
    (typeof value === 'string' && value.trim() === '') ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  );
};