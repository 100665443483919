import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Search from './pages/Search';
import About from './pages/About';
import Contacts from './pages/Contacts';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CreatePostPage from './pages/CreatePostPage';
import EditPostPage from './pages/EditPostPage';
import EditProfilePage from './pages/EditProfilePage';
import AdminDashboard from './pages/admin/Dashboard';
import AdminUsers from './pages/admin/Users';
import AdminProperties from './pages/admin/Properties';
import AdminSettings from './pages/admin/Settings';
import { isAuthenticated, isAdmin } from './services/auth';
import MetaTags from './components/MetaTags';
import './styles.css';

function App() {
  const { i18n } = useTranslation();
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);

  useEffect(() => {
    setIsUserSignedIn(isAuthenticated());
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <BrowserRouter>
      <div className="App">
        <MetaTags /> {/* This will use the default values */}
        <Header 
          changeLanguage={changeLanguage} 
          isUserSignedIn={isUserSignedIn}
          setIsUserSignedIn={setIsUserSignedIn}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<Search />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contacts />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          
          {/* Listing Management Routes */}
          <Route path="/listings/create" element={<CreatePostPage />} />
          <Route path="/listings/edit/:id" element={<EditPostPage />} />
          <Route path="/profile/edit" element={<EditProfilePage />} />
          
          {/* Admin Routes */}
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/users" element={<AdminUsers />} />
          <Route path="/admin/properties" element={<AdminProperties />} />
          <Route path="/admin/settings" element={<AdminSettings />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;