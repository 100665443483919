import React, { useEffect } from 'react';
import { X } from 'lucide-react';
import PropTypes from 'prop-types';

const Toast = ({ message, type = 'error', onClose, duration = 5000, showOverlay = true }) => {
  useEffect(() => {
    if (duration) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // Handle both string messages and arrays of messages
  const messages = Array.isArray(message) ? message : [message];

  const toastContent = (
    <div 
      className="toast-notification"
      role="alert"
      data-type={type}
    >
      <div className="toast-message">
        {messages.map((msg, index) => (
          <div key={index} className="toast-message-item">
            {msg}
          </div>
        ))}
      </div>
      <button
        onClick={onClose}
        className="toast-close-button"
        aria-label="Close"
      >
        <X size={16} />
      </button>
    </div>
  );

  if (showOverlay) {
    return (
      <div className="toast-overlay" onClick={handleOverlayClick}>
        {toastContent}
      </div>
    );
  }

  return toastContent;
};

Toast.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  onClose: PropTypes.func.isRequired,
  duration: PropTypes.number,
  showOverlay: PropTypes.bool
};

export default Toast;