import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  UsersIcon, 
  HomeIcon, 
  BuildingOfficeIcon,
  ShoppingBagIcon
} from '@heroicons/react/24/outline';
import AdminLayout from './Layout';
import Chart, { prepareLineChartData, prepareBarChartData, prepareDoughnutChartData } from '../../components/admin/Chart';
import MaterialTable from '../../components/admin/MaterialTable';
import StatCard from '../../components/admin/StatCard';
import { isAdmin } from '../../services/auth';
import { getUsers, getProperties } from '../../services/admin';

// Helper function to format numbers with commas
const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    users: [],
    properties: [],
    isLoading: true,
    error: null,
  });

  const [userChartData, setUserChartData] = useState(null);
  const [propertyChartData, setPropertyChartData] = useState(null);
  const [propertyTypeData, setPropertyTypeData] = useState(null);
  const [propertyStatusData, setPropertyStatusData] = useState(null);
  const [timeRange, setTimeRange] = useState('month'); // 'week', 'month', 'year'

  useEffect(() => {
    // Redirect if not admin
    if (!isAdmin()) {
      navigate('/');
      return;
    }

    const fetchDashboardData = async () => {
      try {
        setData(prev => ({ ...prev, isLoading: true }));
        const [users, properties] = await Promise.all([
          getUsers(),
          getProperties(),
        ]);

        setData({
          users,
          properties,
          isLoading: false,
          error: null,
        });
        
        // Prepare chart data once we have the data
        prepareChartData(users, properties, timeRange);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setData(prev => ({
          ...prev,
          isLoading: false,
          error: 'Failed to load dashboard data'
        }));
      }
    };

    fetchDashboardData();
  }, [navigate, timeRange]);

  const prepareChartData = (users, properties, range) => {
    // Get the current date and calculate dates based on range
    const now = new Date();
    let startDate;
    let dateFormat;
    let interval;

    switch(range) {
      case 'week':
        startDate = new Date(now);
        startDate.setDate(now.getDate() - 7);
        dateFormat = date => date.toLocaleDateString('en-US', { weekday: 'short' });
        interval = 'day';
        break;
      case 'year':
        startDate = new Date(now);
        startDate.setFullYear(now.getFullYear() - 1);
        dateFormat = date => date.toLocaleDateString('en-US', { month: 'short' });
        interval = 'month';
        break;
      case 'month':
      default:
        startDate = new Date(now);
        startDate.setMonth(now.getMonth() - 1);
        dateFormat = date => date.getDate();
        interval = 'day';
        break;
    }

    // Generate date labels
    const dateLabels = generateDateLabels(startDate, now, interval, dateFormat);

    // Process user data
    const usersByDate = countItemsByDate(users, startDate, interval, 'created_at');
    const userDataPoints = mapCountsToDateLabels(dateLabels, usersByDate);

    // Process property data
    const propertiesByDate = countItemsByDate(properties, startDate, interval, 'created_at');
    const propertyDataPoints = mapCountsToDateLabels(dateLabels, propertiesByDate);

    // Process property types
    const propertyTypes = countPropertyTypes(properties);

    // Process property status
    const activeProperties = properties.filter(p => p.active).length;
    const inactiveProperties = properties.length - activeProperties;

    // Set chart data
    setUserChartData(prepareLineChartData(
      dateLabels.map(d => typeof d === 'object' ? dateFormat(d) : d),
      [{ label: 'New Users', data: userDataPoints }]
    ));

    setPropertyChartData(prepareBarChartData(
      dateLabels.map(d => typeof d === 'object' ? dateFormat(d) : d),
      [{ label: 'New Properties', data: propertyDataPoints }]
    ));

    setPropertyTypeData(prepareDoughnutChartData(
      Object.keys(propertyTypes),
      Object.values(propertyTypes)
    ));

    setPropertyStatusData(prepareDoughnutChartData(
      ['Active', 'Inactive'],
      [activeProperties, inactiveProperties]
    ));
  };

  // Helper function to generate date labels
  const generateDateLabels = (startDate, endDate, interval, formatFn) => {
    const labels = [];
    const currentDate = new Date(startDate);
    
    while (currentDate <= endDate) {
      labels.push(new Date(currentDate));
      
      if (interval === 'day') {
        currentDate.setDate(currentDate.getDate() + 1);
      } else if (interval === 'month') {
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
    }
    
    return labels;
  };

  // Helper function to count items by date
  const countItemsByDate = (items, startDate, interval, dateField) => {
    const counts = {};
    
    items.forEach(item => {
      if (!item[dateField]) return;
      
      const itemDate = new Date(item[dateField]);
      if (itemDate < startDate) return;
      
      let key;
      if (interval === 'day') {
        key = itemDate.toISOString().split('T')[0]; // YYYY-MM-DD
      } else if (interval === 'month') {
        key = `${itemDate.getFullYear()}-${itemDate.getMonth() + 1}`; // YYYY-MM
      }
      
      counts[key] = (counts[key] || 0) + 1;
    });
    
    return counts;
  };

  // Helper function to map counts to date labels
  const mapCountsToDateLabels = (dateLabels, countsByDate) => {
    return dateLabels.map(date => {
      let key;
      if (typeof date === 'object') {
        if (timeRange === 'month' || timeRange === 'week') {
          key = date.toISOString().split('T')[0]; // YYYY-MM-DD
        } else {
          key = `${date.getFullYear()}-${date.getMonth() + 1}`; // YYYY-MM
        }
      } else {
        key = date;
      }
      
      return countsByDate[key] || 0;
    });
  };

  // Helper function to count property types
  const countPropertyTypes = (properties) => {
    const counts = {};
    
    properties.forEach(property => {
      if (!property.property_type) return;
      
      counts[property.property_type] = (counts[property.property_type] || 0) + 1;
    });
    
    return counts;
  };

  // Calculate stats
  const activeProperties = data.properties.filter(p => p.active).length;
  const inactiveProperties = data.properties.length - activeProperties;
  
  // Calculate user and property change
  const calculateRecentChange = (items, days) => {
    const now = new Date();
    const cutoff = new Date(now);
    cutoff.setDate(now.getDate() - days);
    
    const recentCount = items.filter(item => new Date(item.created_at) >= cutoff).length;
    
    const prevCutoff = new Date(cutoff);
    prevCutoff.setDate(prevCutoff.getDate() - days);
    
    const prevCount = items.filter(item => {
      const date = new Date(item.created_at);
      return date >= prevCutoff && date < cutoff;
    }).length;
    
    if (prevCount === 0) return { percent: 0, type: 'neutral' };
    
    const change = ((recentCount - prevCount) / prevCount) * 100;
    return {
      percent: Math.abs(change).toFixed(1),
      type: change > 0 ? 'increase' : change < 0 ? 'decrease' : 'neutral'
    };
  };

  const userChange = data.users.length > 0 ? calculateRecentChange(data.users, 7) : { percent: 0, type: 'neutral' };
  const propertyChange = data.properties.length > 0 ? calculateRecentChange(data.properties, 7) : { percent: 0, type: 'neutral' };

  return (
    <AdminLayout title="Dashboard" loading={data.isLoading} error={data.error}>
      {/* Time range selector */}
      <div className="admin-time-filter">
        {['week', 'month', 'year'].map(range => (
          <button
            key={range}
            onClick={() => setTimeRange(range)}
            className={`admin-time-option ${timeRange === range ? 'active' : ''}`}
          >
            {range.charAt(0).toUpperCase() + range.slice(1)}
          </button>
        ))}
      </div>

      {/* Stats row */}
      <div className="admin-stats-grid">
        <StatCard
          title="Total Users"
          value={formatNumber(data.users.length)}
          icon={<UsersIcon />}
          color="blue"
          change={userChange.percent}
          changeType={userChange.type}
          description="vs previous period"
        />
        <StatCard
          title="Total Properties"
          value={formatNumber(data.properties.length)}
          icon={<HomeIcon />}
          color="green"
          change={propertyChange.percent}
          changeType={propertyChange.type}
          description="vs previous period"
        />
        <StatCard
          title="Active Properties"
          value={formatNumber(activeProperties)}
          icon={<BuildingOfficeIcon />}
          color="purple"
          description={`${((activeProperties / Math.max(data.properties.length, 1)) * 100).toFixed(0)}% of total`}
        />
        <StatCard
          title="Inactive Properties"
          value={formatNumber(inactiveProperties)}
          icon={<ShoppingBagIcon />}
          color="amber"
          description={`${((inactiveProperties / Math.max(data.properties.length, 1)) * 100).toFixed(0)}% of total`}
        />
      </div>

      {/* Charts */}
      <div className="admin-charts-grid">
        {/* User Growth Chart */}
        <div className="admin-chart-card">
          <h3 className="admin-chart-title">User Growth</h3>
          {userChartData ? (
            <Chart 
              type="line" 
              data={userChartData}
              height={300}
            />
          ) : (
            <div className="h-[300px] flex items-center justify-center">
              <p className="text-gray-500">Loading chart data...</p>
            </div>
          )}
        </div>

        {/* Property Growth Chart */}
        <div className="admin-chart-card">
          <h3 className="admin-chart-title">Property Listings</h3>
          {propertyChartData ? (
            <Chart 
              type="bar" 
              data={propertyChartData}
              height={300}
            />
          ) : (
            <div className="h-[300px] flex items-center justify-center">
              <p className="text-gray-500">Loading chart data...</p>
            </div>
          )}
        </div>
      </div>

      {/* Additional Charts */}
      <div className="admin-charts-grid">
        {/* Property Types Doughnut Chart */}
        <div className="admin-chart-card">
          <h3 className="admin-chart-title">Property Types</h3>
          {propertyTypeData ? (
            <Chart 
              type="doughnut" 
              data={propertyTypeData}
              height={300}
              options={{
                plugins: {
                  legend: {
                    position: 'right'
                  }
                }
              }}
            />
          ) : (
            <div className="h-[300px] flex items-center justify-center">
              <p className="text-gray-500">Loading chart data...</p>
            </div>
          )}
        </div>

        {/* Property Status Doughnut Chart */}
        <div className="admin-chart-card">
          <h3 className="admin-chart-title">Property Status</h3>
          {propertyStatusData ? (
            <Chart 
              type="doughnut" 
              data={propertyStatusData}
              height={300}
              options={{
                plugins: {
                  legend: {
                    position: 'right'
                  }
                }
              }}
            />
          ) : (
            <div className="h-[300px] flex items-center justify-center">
              <p className="text-gray-500">Loading chart data...</p>
            </div>
          )}
        </div>
      </div>
      
      {/* Recent Users Table */}
      <div className="admin-table-card">
        <div className="admin-table-header">
          <h3 className="admin-table-title">Recent Users</h3>
        </div>
        <MaterialTable 
          columns={[
            { id: 'id', label: 'ID' },
            { id: 'username', label: 'Username' },
            { id: 'email', label: 'Email' },
            { id: 'fullname', label: 'Full Name' },
            { 
              id: 'created_at', 
              label: 'Joined', 
              render: (row) => new Date(row.created_at).toLocaleDateString() 
            }
          ]} 
          data={data.users.slice(0, 5)} 
          isLoading={data.isLoading}
          showSearch={false}
        />
      </div>
      
      {/* Recent Properties Table */}
      <div className="admin-table-card">
        <div className="admin-table-header">
          <h3 className="admin-table-title">Recent Properties</h3>
        </div>
        <MaterialTable 
          columns={[
            { id: 'id', label: 'ID' },
            { 
              id: 'title', 
              label: 'Title',
              render: (row) => (
                <div className="truncate max-w-xs" title={row.title}>
                  {row.title}
                </div>
              )
            },
            { id: 'price', label: 'Price', render: (row) => `$${row.price?.toLocaleString() || 0}` },
            { 
              id: 'location', 
              label: 'Location',
              render: (row) => `${row.city}, ${row.country}` 
            },
            { 
              id: 'created_at', 
              label: 'Added', 
              render: (row) => new Date(row.created_at).toLocaleDateString() 
            }
          ]} 
          data={data.properties.slice(0, 5)} 
          isLoading={data.isLoading}
          showSearch={false}
        />
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;