import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  HomeIcon, 
  UsersIcon, 
  BuildingOfficeIcon, 
  Cog6ToothIcon,
  ChartBarIcon,
  Bars3Icon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import AdminMenu from '../../components/admin/AdminMenu';

const AdminLayout = ({ 
  title, 
  loading = false, 
  error = null, 
  children,
  actionButton = null,
}) => {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Mobile menu items
  const menuItems = [
    { 
      path: '/admin/dashboard', 
      label: 'Dashboard',
      icon: <ChartBarIcon className="admin-mobile-menu-icon" /> 
    },
    { 
      path: '/admin/users', 
      label: 'Users',
      icon: <UsersIcon className="admin-mobile-menu-icon" /> 
    },
    { 
      path: '/admin/properties', 
      label: 'Properties',
      icon: <BuildingOfficeIcon className="admin-mobile-menu-icon" /> 
    },
    { 
      path: '/admin/settings', 
      label: 'Settings',
      icon: <Cog6ToothIcon className="admin-mobile-menu-icon" /> 
    },
    { 
      path: '/', 
      label: 'Home',
      icon: <HomeIcon className="admin-mobile-menu-icon" /> 
    }
  ];

  if (loading) {
    return (
      <div className="admin flex items-center justify-center">
        <div className="text-center">
          <div className="inline-block animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
          <p className="mt-4 text-gray-600">Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin flex items-center justify-center">
        <div className="admin-card text-center max-w-md">
          <div className="text-red-500 text-5xl mb-4">⚠️</div>
          <h2 className="text-xl font-bold mb-4">Error</h2>
          <p className="mb-6">{error}</p>
          <Link to="/admin/dashboard" className="admin-btn admin-btn-primary">
            Return to Dashboard
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="admin">
      {/* Sidebar */}
      <AdminMenu />
      
      {/* Main Content */}
      <div className="admin-content">
        {/* Header */}
        <header className="admin-header">
          <h1>{title}</h1>
          {actionButton && (
            <div>{actionButton}</div>
          )}
        </header>
        
        {/* Mobile menu */}
        <div className="admin-mobile-menu">
          <div className="admin-mobile-menu-items">
            {menuItems.map(item => (
              <Link 
                key={item.path}
                to={item.path}
                className={`admin-mobile-menu-link ${location.pathname === item.path ? 'active' : ''}`}
              >
                {item.icon}
                <span className="admin-mobile-menu-text">{item.label}</span>
              </Link>
            ))}
          </div>
        </div>
        
        {/* Content */}
        {children}
        
        {/* Footer */}
        <footer className="admin-card mt-8 text-center text-sm text-gray-500">
          <p>© {new Date().getFullYear()} Habittatt. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default AdminLayout;