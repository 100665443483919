import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminLayout from './Layout';
import { isAdmin } from '../../services/auth';
import { createAdmin } from '../../services/admin';
import { ShieldCheckIcon, KeyIcon, XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

const AdminSettings = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    adminSecret: ''
  });

  useEffect(() => {
    // Redirect if not admin
    if (!isAdmin()) {
      navigate('/');
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear previous messages when the user starts typing again
    setError(null);
    setSuccess(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      await createAdmin(formData.email, formData.adminSecret);
      setSuccess('Admin user created successfully');
      setFormData({
        email: '',
        adminSecret: ''
      });
    } catch (err) {
      console.error('Error creating admin:', err);
      setError(err.toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminLayout title="Admin Settings">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Admin Creation Form */}
        <div className="admin-card lg:col-span-2">
          <div className="admin-card-header">
            <h2 className="admin-card-title">
              <ShieldCheckIcon className="h-5 w-5 inline-block mr-2 text-indigo-600" />
              Create Admin User
            </h2>
          </div>
          
          <p className="text-gray-600 mb-6">
            Grant admin privileges to an existing user by providing their email address and the admin secret key.
          </p>
          
          {success && (
            <div className="bg-green-50 border-l-4 border-green-500 p-4 mb-6">
              <div className="flex items-center">
                <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                <p className="text-sm text-green-700">{success}</p>
              </div>
            </div>
          )}
          
          {error && (
            <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
              <div className="flex items-center">
                <XCircleIcon className="h-5 w-5 text-red-500 mr-2" />
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          )}
          
          <form onSubmit={handleSubmit}>
            <div className="admin-form-group">
              <label htmlFor="email" className="admin-form-label">
                User Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                autoComplete="email"
                required
                placeholder="Enter email of existing user"
                className="admin-form-input"
              />
              <p className="mt-1 text-sm text-gray-500">
                The user must already have an account in the system.
              </p>
            </div>

            <div className="admin-form-group">
              <label htmlFor="adminSecret" className="admin-form-label">
                Admin Secret
              </label>
              <input
                id="adminSecret"
                name="adminSecret"
                type="password"
                value={formData.adminSecret}
                onChange={handleInputChange}
                autoComplete="current-password"
                required
                placeholder="Enter admin secret key"
                className="admin-form-input"
              />
              <p className="mt-1 text-sm text-gray-500">
                This is the secret key defined in the server's environment variables.
              </p>
            </div>

            <div className="admin-form-group">
              <button
                type="submit"
                disabled={loading}
                className={`admin-btn admin-btn-primary ${loading ? 'opacity-75 cursor-not-allowed' : ''}`}
              >
                {loading ? 'Processing...' : 'Create Admin User'}
              </button>
            </div>
          </form>
        </div>

        {/* Admin Information */}
        <div className="admin-card">
          <div className="admin-card-header">
            <h2 className="admin-card-title">Admin Privileges</h2>
          </div>
          
          <div className="prose prose-sm max-w-none text-gray-600">
            <p>
              Admin users have full access to manage the platform, including:
            </p>
            <ul className="list-disc pl-5 space-y-1 mt-2">
              <li>View and edit all user accounts</li>
              <li>Change user roles (admin/regular user)</li>
              <li>View, edit, and delete all property listings</li>
              <li>Access detailed analytics and reports</li>
              <li>Create other admin users</li>
            </ul>
            
            <div className="mt-4 p-3 bg-yellow-50 rounded-md">
              <p className="text-yellow-700 font-medium">Important:</p>
              <p className="text-yellow-600 text-sm mt-1">
                Only grant admin access to trusted individuals with a legitimate need for administrative privileges. Admin users have complete control over the platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminSettings;