import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { login, signup } from '../services/auth';
import { countries } from '../utils/helpers';
import ModalHeader from './ModalHeader';
import Toast from './Toast';

const AuthModal = ({ onClose, onSuccess }) => {
  const [activeTab, setActiveTab] = useState('signin');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [country, setCountry] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [toast, setToast] = useState(null);
  const { t } = useTranslation();

  const showToast = (message, type = 'error') => {
    setToast({ message, type });
  };

  const validateForm = () => {
    if (!email || !password) {
      showToast(t('Please fill in all required fields'));
      return false;
    }

    if (!email.includes('@')) {
      showToast(t('Please enter a valid email address'));
      return false;
    }

    if (password.length < 6) {
      showToast(t('Password must be at least 6 characters long'));
      return false;
    }

    if (activeTab === 'signup') {
      if (!fullName || !country || !phoneNumber) {
        showToast(t('Please fill in all required fields'));
        return false;
      }

      if (phoneNumber.length < 8) {
        showToast(t('Please enter a valid phone number'));
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    try {
      if (activeTab === 'signin') {
        await login(email, password);
        showToast(t('Successfully signed in'), 'success');
        setTimeout(() => {
          onSuccess();
        }, 1000);
      } else {
        const phoneCountryCode = countries[country]?.phoneCountryCode || '';
        const fullPhoneNumber = phoneCountryCode + phoneNumber;
        console.log('Sending signup data:', {
          fullname: fullName,
          email,
          password,
          country,
          phone: fullPhoneNumber
        });
        await signup(fullName, email, password, country, fullPhoneNumber);
        showToast(t('Successfully signed up'), 'success');
        setTimeout(() => {
          onSuccess();
        }, 1000);
      }
    } catch (error) {
      console.error('Auth error:', error);
      
      // Check for detailed error structure we implemented
      if (error.details && Array.isArray(error.details) && error.details.length > 0) {
        // Show all detailed error messages
        showToast(error.details);
      } else {
        // Fallback to the old way if details aren't available
        const errorMessage = error.message || 
          (activeTab === 'signin' 
            ? t('Invalid email or password') 
            : t('Error during sign up'));
        showToast(errorMessage);
      }
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="admin-modal modal-content" onClick={e => e.stopPropagation()}>
        <div className="admin-modal-header">
          <h2 className="admin-modal-title">
            {activeTab === 'signin' ? t('Sign In') : t('Sign Up')}
          </h2>
          <button className="admin-modal-close" onClick={onClose}>
            <span>×</span>
          </button>
        </div>
        
        <div className="admin-modal-body modal-body">
          <div className="admin-dashboard-filters">
            <button
              className={`admin-tab-btn ${activeTab === 'signin' ? 'active' : ''}`}
              onClick={() => setActiveTab('signin')}
            >
              {t('Sign In')}
            </button>
            <button
              className={`admin-tab-btn ${activeTab === 'signup' ? 'active' : ''}`}
              onClick={() => setActiveTab('signup')}
            >
              {t('Sign Up')}
            </button>
          </div>
          
          <form onSubmit={handleSubmit}>
            <div className="admin-form-group">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('Email')}
                className="admin-form-input"
                required
              />
            </div>
            
            <div className="admin-form-group">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t('Password')}
                className="admin-form-input"
                required
              />
            </div>
            
            {activeTab === 'signup' && (
              <>
                <div className="admin-form-group">
                  <input
                    type="text"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    placeholder={t('Full Name')}
                    className="admin-form-input"
                    required
                  />
                </div>
                
                <div className="admin-form-group">
                  <select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className="admin-form-select"
                    required
                  >
                    <option value="">{t('Select Country')}</option>
                    {Object.keys(countries).map(countryName => (
                      <option key={countryName} value={countryName}>
                        {t(countryName)}
                      </option>
                    ))}
                  </select>
                </div>
                
                <div className="admin-form-group">
                  <div className="phone-input" style={{display: 'flex'}}>
                    <input
                      type="text"
                      value={countries[country]?.phoneCountryCode || ''}
                      readOnly
                      className="admin-form-input country-code"
                      style={{width: '80px', marginRight: '8px'}}
                    />
                    <input
                      type="tel"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, ''))}
                      placeholder={t('Phone Number')}
                      className="admin-form-input"
                      style={{flex: '1'}}
                      required
                    />
                  </div>
                </div>
              </>
            )}
            
            <button type="submit" className="admin-btn admin-btn-primary">
              {activeTab === 'signin' ? t('Sign In') : t('Sign Up')}
            </button>
          </form>

          {toast && (
            <Toast
              message={toast.message}
              type={toast.type}
              onClose={() => setToast(null)}
              duration={5000}
              showOverlay={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthModal;