import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Line, Bar, Doughnut } from 'react-chartjs-2';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

// Color palette
const colors = {
  primaryLine: 'rgba(79, 70, 229, 0.8)',
  primaryFill: 'rgba(79, 70, 229, 0.2)',
  secondaryLine: 'rgba(16, 185, 129, 0.8)',
  secondaryFill: 'rgba(16, 185, 129, 0.2)',
  tertiaryLine: 'rgba(239, 68, 68, 0.8)',
  tertiaryFill: 'rgba(239, 68, 68, 0.2)',
  // Doughnut chart colors
  doughnut: [
    'rgba(79, 70, 229, 0.8)',
    'rgba(16, 185, 129, 0.8)',
    'rgba(239, 68, 68, 0.8)',
    'rgba(245, 158, 11, 0.8)',
    'rgba(59, 130, 246, 0.8)',
    'rgba(126, 34, 206, 0.8)',
  ],
};

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        font: {
          family: 'Inter, sans-serif',
          size: 12
        }
      }
    },
    tooltip: {
      backgroundColor: 'rgba(17, 24, 39, 0.9)',
      titleFont: {
        family: 'Inter, sans-serif',
        size: 14
      },
      bodyFont: {
        family: 'Inter, sans-serif',
        size: 13
      },
      padding: 12,
      cornerRadius: 6,
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      },
      ticks: {
        font: {
          family: 'Inter, sans-serif',
          size: 11
        }
      }
    },
    y: {
      grid: {
        color: 'rgba(209, 213, 219, 0.5)'
      },
      ticks: {
        font: {
          family: 'Inter, sans-serif',
          size: 11
        }
      }
    }
  }
};

const Chart = ({ 
  type, 
  data, 
  options = {}, 
  height = 250,
}) => {
  // Deep merge options with defaults
  const mergedOptions = {
    ...defaultOptions,
    ...options,
    responsive: true,
    maintainAspectRatio: false, // This is crucial for aspect ratio changes
    onResize: (chart, size) => {
      // This callback allows additional logic when chart is resized
      // We could add custom scaling logic here if needed
    },
    plugins: {
      ...defaultOptions.plugins,
      ...(options.plugins || {}),
      legend: {
        ...defaultOptions.plugins.legend,
        ...(options.plugins?.legend || {})
      },
      tooltip: {
        ...defaultOptions.plugins.tooltip,
        ...(options.plugins?.tooltip || {})
      }
    },
    scales: {
      ...defaultOptions.scales,
      ...(options.scales || {})
    }
  };

  const renderChart = () => {
    switch(type) {
      case 'line':
        return <Line data={data} options={mergedOptions} />;
      case 'bar':
        return <Bar data={data} options={mergedOptions} />;
      case 'doughnut':
        // Remove scale options for doughnut charts
        const { scales, ...doughnutOptions } = mergedOptions;
        return <Doughnut data={data} options={doughnutOptions} />;
      default:
        return <Line data={data} options={mergedOptions} />;
    }
  };

  // Use a responsive container div that will adjust to aspect ratio changes
  return (
    <div className="chart-responsive-container" style={{ position: 'relative', height: `${height}px`, width: '100%' }}>
      {renderChart()}
    </div>
  );
};

export const prepareLineChartData = (
  labels, 
  datasets, 
  colors = {
    primaryLine: 'rgba(79, 70, 229, 0.8)',
    primaryFill: 'rgba(79, 70, 229, 0.2)',
    secondaryLine: 'rgba(16, 185, 129, 0.8)',
    secondaryFill: 'rgba(16, 185, 129, 0.2)',
  }
) => {
  // Get CSS variables for responsive sizing if available
  const getResponsiveValue = (property, fallback) => {
    if (typeof window !== 'undefined' && window.getComputedStyle) {
      const value = getComputedStyle(document.documentElement).getPropertyValue(property);
      return value ? value.trim() : fallback;
    }
    return fallback;
  };
  
  const pointRadius = getResponsiveValue('--chart-point-radius', '5px').replace('px', '');
  const pointHoverRadius = getResponsiveValue('--chart-point-hover-radius', '7px').replace('px', '');
  const lineWidth = getResponsiveValue('--chart-line-width', '2px').replace('px', '');
  
  return {
    labels,
    datasets: datasets.map((dataset, index) => {
      const isFirst = index === 0;
      return {
        label: dataset.label,
        data: dataset.data,
        borderColor: isFirst ? colors.primaryLine : colors.secondaryLine,
        backgroundColor: isFirst ? colors.primaryFill : colors.secondaryFill,
        borderWidth: dataset.borderWidth || parseFloat(lineWidth) || 2,
        pointRadius: dataset.pointRadius || parseFloat(pointRadius) || 5,
        pointHoverRadius: dataset.pointHoverRadius || parseFloat(pointHoverRadius) || 7,
        fill: dataset.fill !== undefined ? dataset.fill : true,
        tension: 0.4,
        ...dataset
      };
    })
  };
};

export const prepareBarChartData = (
  labels, 
  datasets, 
  colors = {
    primaryLine: 'rgba(79, 70, 229, 0.8)',
    primaryFill: 'rgba(79, 70, 229, 0.2)',
    secondaryLine: 'rgba(16, 185, 129, 0.8)',
    secondaryFill: 'rgba(16, 185, 129, 0.2)',
  }
) => {
  return {
    labels,
    datasets: datasets.map((dataset, index) => {
      const isFirst = index === 0;
      return {
        label: dataset.label,
        data: dataset.data,
        backgroundColor: isFirst ? colors.primaryLine : colors.secondaryLine,
        borderWidth: 0,
        borderRadius: 4,
        ...dataset
      };
    })
  };
};

export const prepareDoughnutChartData = (labels, data, customColors = null) => {
  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor: customColors || colors.doughnut,
        borderWidth: 0,
        hoverOffset: 4
      }
    ]
  };
};

export { colors };
export default Chart;