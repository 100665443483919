import axios from 'axios';
import { API_BASE_URL } from '../config';

// Check if user is logged in
export const isAuthenticated = () => {
  return localStorage.getItem('token') !== null;
};

// Get current user data
export const getUser = async (fetchFromDB = false) => {
  if (fetchFromDB) {
    try {
      const response = await axios.get(`${API_BASE_URL}/users/profile`, {
        headers: getAuthHeader()
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      throw error.response?.data?.error || 'Failed to fetch user profile';
    }
  } else {
    const userData = localStorage.getItem('user');
    return userData ? JSON.parse(userData) : null;
  }
};

// Check if user is admin
export const isAdmin = () => {
  // Use the synchronous version to avoid async issues in UI components
  const userData = localStorage.getItem('user');
  const user = userData ? JSON.parse(userData) : null;
  return user && user.role === 'admin';
};

// Login user
export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/signin`, { email, password });
    const data = response.data;
    
    const userData = {
      id: String(data.userId),
      fullname: data.fullname,
      role: data.role,
    };

    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(userData));
    
    return data;
  } catch (error) {
    throw error.response?.data?.error || 'Login failed';
  }
};

// Register user with enhanced error handling
export const register = async (userData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/signup`, userData);
    return response.data;
  } catch (error) {
    // Check for the detailed error format from our enhanced API
    if (error.response?.data?.details && Array.isArray(error.response.data.details)) {
      // Join all detailed error messages into a single string
      throw {
        message: error.response.data.error || 'Registration failed',
        details: error.response.data.details,
        code: error.response.data.code || 'UNKNOWN_ERROR'
      };
    } else {
      // Fallback to just the main error message or a generic one
      throw {
        message: error.response?.data?.error || 'Registration failed',
        details: ['An unexpected error occurred during registration. Please try again.'],
        code: 'UNKNOWN_ERROR'
      };
    }
  }
};

// Signup function (to maintain compatibility with existing components)
export const signup = async (fullname, email, password, country, phone) => {
  try {
    // Create the user data object with the correct structure for the API
    const userData = {
      fullname,
      email,
      password,
      country,
      phone
    };
    
    const response = await axios.post(`${API_BASE_URL}/signup`, userData);
    return response.data;
  } catch (error) {
    // Check for the detailed error format from our enhanced API
    if (error.response?.data?.details && Array.isArray(error.response.data.details)) {
      // Join all detailed error messages into a single string
      throw {
        message: error.response.data.error || 'Registration failed',
        details: error.response.data.details,
        code: error.response.data.code || 'UNKNOWN_ERROR'
      };
    } else {
      // Fallback to just the main error message or a generic one
      throw {
        message: error.response?.data?.error || 'Registration failed',
        details: ['An unexpected error occurred during registration. Please try again.'],
        code: 'UNKNOWN_ERROR'
      };
    }
  }
};

// Logout user
export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};

// Get auth header for API calls
export const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

// Get auth token (used by components)
export const getAuthToken = () => {
  return localStorage.getItem('token');
};

// Edit user profile
export const editProfile = async (userData) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/users/profile`, 
      userData, 
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'Failed to update profile';
  }
};